import React, { AnchorHTMLAttributes } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './styles.css'

interface ButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  loading?: boolean
  hide?: boolean
  to?: string
  customClass?: string
}

const Button: React.FC<ButtonProps> = ({
  loading,
  hide,
  to,
  customClass,
  ...rest
}) => {
  if (!hide) {
    return (
      <div className={`dkxbutton ${customClass}`}>
        {to ? (
          <Link {...rest} to={to}>
            {!loading ? rest.children : <FaSpinner />}
          </Link>
        ) : (
          <a {...rest}>{!loading ? rest.children : <FaSpinner />}</a>
        )}
      </div>
    )
  } else {
    return <div></div>
  }
}

export default Button
