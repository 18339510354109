import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import Input from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import Sidebar from '../../components/Sidebar'
import { iRow, Table } from '../../components/Table'
import { iCliente } from '../../interfaces/interfaces'
import api from '../../services/api'
import './styles.css'

import { FaEdit, FaMinusSquare, FaPlus } from 'react-icons/fa'
import Dialog from '../../components/Dialog'

const Cliente: React.FC = () => {
  const [tableLoading, setTableLoading] = useState<boolean>(true)
  const [clientes, setClientes] = useState<Array<iRow>>([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogOpen2, setDialogOpen2] = useState(false)
  const [clienteCancelar, setclienteCancelar] = useState(0)

  const [dialogTitle, setdialogTitle] = useState('')
  const [dialogText, setdialogText] = useState('')

  useEffect(() => {
    buscaClientes()
  }, [])

  function confirmaCancelaCliente(idcliente: number) {
    setclienteCancelar(idcliente)
    setDialogOpen(true)
  }

  function cancelaCliente() {
    setDialogOpen(false)
    api
      .post('/cliente/cancelar', { idcliente: clienteCancelar })
      .then(res => {
        if (res.data.status === 'err') {
          dialog('Falha ao cancelar o cliente', res.data.message)
        } else {
          const _i = clientes.findIndex((cliente: iRow) => {
            return cliente.rowId === clienteCancelar.toString()
          })

          clientes[_i].values[3] = (
            <div className={`statusCliente clienteInativo`}>
              <span>Inativo</span>
            </div>
          )

          dialog(':)', 'Sucesso')
        }
      })
      .catch(e => {
        dialog(':(', 'Falha ao cancelar o cliente')
      })
  }

  function dialog(title: string, text: string) {
    setdialogTitle(title)
    setdialogText(text)
    setDialogOpen2(true)
  }

  function buscaClientes() {
    api
      .get('/clientes')
      .then(res => {
        const data = res.data as Array<iCliente>
        const clientes: Array<iRow> = data.map((value, index) => {
          return {
            rowId: value.idcliente.toString(),
            values: [
              `${value.txnome_cliente} ${value.txsobrenome_cliente || ''}`,
              value.txemail,
              value.tipoCliente?.txvalor,
              <div
                key={value.idcliente.toString()}
                className={`statusCliente ${value.statusCliente?.tcchave}`}
              >
                <span>{value.statusCliente?.txvalor}</span>
              </div>,
              <div key={value.idcliente.toString()}>
                <Button to={`/cliente/edit/${value.idcliente}`}>
                  <FaEdit />
                </Button>
              </div>,
              <div key={value.idcliente.toString()}>
                <Button
                  className="redButton"
                  onClick={() => {
                    confirmaCancelaCliente(value.idcliente)
                  }}
                >
                  <FaMinusSquare />{' '}
                </Button>
              </div>
            ]
          }
        })
        setClientes(clientes)
        setTableLoading(false)
      })
      .catch(() => {
        alert('Ocorreu um erro ao buscar os Clientes, tente novamente')
      })
  }

  return (
    <div id="page-cliente">
      <PageHeader></PageHeader>
      <Sidebar></Sidebar>
      <div className="container">
        <div className="content">
          <div className="bloco">
            <div className="buttons-container">
              <Button to="/cliente/edit/-1">
                <FaPlus />
                Novo
              </Button>
              {/* <a href="/operador/edit/-1"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>Novo</a> */}
            </div>
          </div>
          <div className="bloco">
            {/* <Input
              type="text"
              label="Filtrar"
              placeholder="digite o nome do operador"
              name="filtro"
              value={filtroNome}
              onChange={(e) => {
                setfiltroNome(e.target.value);
              }}
            /> */}
          </div>
          <div className="bloco">
            <Table
              labels={[
                'Nome',
                'E-mail',
                'Tipo Cliente',
                'Situação',
                'Ações',
                'Cancelar'
              ]}
              data={clientes}
              customClass="table-clientes"
              name="table-clientes"
              qtloading={4}
              loading={tableLoading}
            />
          </div>
        </div>
      </div>

      <Dialog
        title="Atenção!"
        text="Confirma o cancelamento do cliente  ?"
        isOpen={dialogOpen}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        buttons={[
          {
            text: 'Não',
            type: 'cancel'
          },
          {
            text: 'Sim',
            type: 'button',
            onClick: cancelaCliente
          }
        ]}
        onRequestClose={() => {
          setDialogOpen(false)
        }}
      ></Dialog>

      <Dialog
        title={dialogTitle}
        text={dialogText}
        isOpen={dialogOpen2}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        buttons={[
          {
            text: 'Ok',
            type: 'cancel'
          }
        ]}
        onRequestClose={() => {
          setDialogOpen2(false)
        }}
      ></Dialog>
    </div>
  )
}

export default Cliente
