import React, {
  InputHTMLAttributes,
  LegacyRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { FaUpload } from 'react-icons/fa'

import './styles.css'

interface InputFileProps extends InputHTMLAttributes<HTMLInputElement> {
  customClass?: string
  name: string
  label: string
  ref?: any
  fileExtensions?: Array<string>
  onFileChange?(files: FileList | null): void
}

interface iRelatorio extends React.HTMLProps<HTMLDivElement> {
  titulo: string
  ref?: any
}

const InputFile: React.FC<InputFileProps> = React.forwardRef(function InputFile(
  { customClass, label, name, fileExtensions, onFileChange, ...rest },
  ref: any
) {
  const [nomeArquivos, setNomeArquivos] = useState<string>('')

  // useEffect(() => {
  //   setNomeArquivos(rest.placeholder || "");
  // }, [])

  useEffect(() => {
    console.log('Init InputFile')
    setNomeArquivos(rest.placeholder || '')
  }, [])

  function makeAcceptAttr(fileExtensions: Array<string>) {
    let acceptAttr = ''
    let virg = ''

    fileExtensions.map((value, index) => {
      acceptAttr += virg + value
      virg = ','
      return acceptAttr
    })
    return acceptAttr
  }

  function FileListItems(files: Array<File>) {
    const b = new ClipboardEvent('').clipboardData || new DataTransfer()
    for (let i = 0, len = files.length; i < len; i++) b.items.add(files[i])
    return b.files
  }

  function checkMimeType(files: FileList | null) {
    //define message container
    let err = ''
    const fileArray = Array.from(files || [])
    // list allow mime type
    const types = fileExtensions || []
    // loop access array
    if (fileArray) {
      for (let x = 0; x < fileArray.length; x++) {
        // compare file type find doesn't matach
        if (types.every(type => fileArray[x].type !== type)) {
          // create error message and assign to container
          err += fileArray[x].type + ' is not a supported format\n'
          fileArray.splice(x, 1)
        }
      }

      if (err !== '') {
        // if message not same old that mean has error
        if (files && files?.length > 1) {
          alert(
            'Um dos arquivos selecionados possui um formato não suportado e foi removido!'
          )
        } else {
          alert(
            'O arquivo selecionado possui um formato não suportado e foi removido!'
          )
        }
      }
    }
    return FileListItems(fileArray)
  }

  function handleChangeFile(files: FileList | null) {
    if (fileExtensions) files = checkMimeType(files)

    const fileArray = Array.from(files || [])
    let fileNames = ''
    fileArray.map((file, index) => {
      return (fileNames += ' ' + file.name)
    })

    setNomeArquivos(fileNames || rest.placeholder || '')

    if (onFileChange) {
      onFileChange(files || null)
    }
  }

  useImperativeHandle(ref, () => ({
    clear() {
      setNomeArquivos(rest.placeholder || '')
    }
  }))

  return (
    <div className={'input-file-block' + ' ' + customClass}>
      <label htmlFor={name}>{label}</label>
      <div className="input">
        <label className="icon" htmlFor={name}>
          <FaUpload />
        </label>
        <label className="filename" htmlFor={name}>
          {nomeArquivos}
        </label>
        <input
          ref={ref}
          type="file"
          id={name}
          accept={fileExtensions ? makeAcceptAttr(fileExtensions) : ''}
          {...rest}
          onChange={e => {
            handleChangeFile(e.target.files)
          }}
        />
      </div>
    </div>
  )
})

export default InputFile
