import React, { AnchorHTMLAttributes, useEffect, useRef, useState } from 'react'

import Button from '../../../components/Button'
import Input from '../../../components/Input'
import PageHeader from '../../../components/PageHeader'
import RadioButton from '../../../components/RadioButton'
import { Select } from '../../../components/Select'
import Sidebar from '../../../components/Sidebar'
import { iRow, Table } from '../../../components/Table'
import {
  iCidade,
  iCliente,
  iClienteEndereco,
  iClienteVeiculo,
  iClienteVeiculoCliente,
  iSelect,
  iTabelaCodigo,
  iv1OperadorCliente
} from '../../../interfaces/interfaces'
import api from '../../../services/api'
import {
  maskCEP,
  maskCNPJ,
  maskCPF,
  maskPlaca,
  validaCPF
} from '../../../services/utils'

import './styles.css'
import { useHistory, useParams } from 'react-router'

import Dialog from '../../../components/Dialog'
import TabelaCodigoService from '../../../services/TabelaCodigo'

import { FaEdit } from 'react-icons/fa'

interface iOperacaoInstalacao {
  idcliente_veiculo: number
  tctipo_operacao: string
  dtinstalacao: string
  dtinicio_rastreamento: string
  idoperador: number
  vldesconto: number
  vlfinal: number
  vltabela_preco: number
  tctipo_pagamento_instalacao: string
  tctipo_pagamento_mensalidade: string
  iddispositivo: number
  idinstalador: number
  idtabela_preco: number
}

interface iTabelaPreco {
  idtabela_preco: number
  nmtabela_preco: string
  dtinicio: Date
  dtfim: Date
  tabela_preco_item: {
    idtabela_preco_item: number
    idtabela_preco: number
    vlinstalacao: number
    vlmensalidade: number
    dtinicio: Date
    dtfim: Date
  }
}

const Instalacao: React.FC = () => {
  return (<div></div>)
}

/* const Instalacao: React.FC = () => {
  const [modoTela, setModoTela] = useState<'NEW' | 'EDIT'>('NEW')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dadosCliente, setDadosCliente] = useState<iv1OperadorCliente>({
    tctipo_cliente: 'pessoaFisica'
  } as iv1OperadorCliente)
  const [clienteVeiculosRow, setClienteVeiculosRow] = useState<Array<iRow>>([])
  const [clienteVeiculos, setClienteVeiculos] = useState<
    Array<iClienteVeiculo>
  >([])
  const [dadosEndereco, setDadosEndereco] = useState<iClienteEndereco>()

  const [instaladores, setInstaladores] = useState<iSelect[]>([])
  const [dispositivoDisponivel, setdispositivoDisponivel] = useState<iSelect[]>(
    []
  )
  const [tipoPagamentoInstalacao, settipoPagamentoInstalacao] = useState<
    iSelect[]
  >([])
  const [tipoPagamentoMensalidade, settipoPagamentoMensalidade] = useState<
    iSelect[]
  >([])
  const [tabelaPreco, settabelaPreco] = useState<iSelect[]>([])
  const [tabelaPrecoCompleta, settabelaPrecoCompleta] = useState<
    iTabelaPreco[]
  >([])
  const [dtinstalacao, setdtinstalacao] = useState<string>('')
  const [dtinicio_rastreamento, setdtinicio_rastreamento] = useState<string>()
  const [clienteVeiculo, setClienteVeiculo] = useState<iClienteVeiculo>(
    {} as iClienteVeiculo
  )

  const [operacaoInstalacao, setoperacaoInstalacao] =
    useState<iOperacaoInstalacao>({} as iOperacaoInstalacao)

  const tabelaCodigoService = new TabelaCodigoService()

  const navigation = useHistory()

  const logradouroRef = useRef<HTMLInputElement>()
  const numeroRef = useRef<HTMLInputElement>()
  const cpfcnpjref = useRef<HTMLInputElement>()

  interface iParams {
    idcliente?: string
    idcliente_veiculo?: string
  }

  interface iInstalador {
    idinstalador: number
    nminstalador: string
  }

  const params = useParams<iParams>()

  useEffect(() => {
    let idCliente = '1'
    let idClienteVeiculo = '68'
    if (params.idcliente !== '-1') {
      setModoTela('EDIT')
      console.log('idcleitne inicio tela: ', params.idcliente)

      if (params.idcliente) {
        idCliente = params.idcliente
      }
      if (params.idcliente_veiculo) {
        idClienteVeiculo = params.idcliente_veiculo
      }
      // buscaDadosCliente(parseInt(idCliente));
    }

    buscaDadosTabelaPreco()
    buscaDadosInstalador()
    buscaDadosTipoPagamentoMensalidade()
    buscaDadosTipoPagamentoInstalacao()
    buscaDispositivoDisponivel()
  }, [])

  function buscaDadosVeiculo(_dispositivos: iSelect[]) {
    api.get(`/clienteVeiculo/naoinstalado`).then(res => {
      if (res.data.length) {
        const _clienteVeiculosRow: Array<iRow> = []

        res.data.map((clienteVeiculo: iClienteVeiculoCliente) => {
          // _clienteVeiculosRow.push(makeCarRow(clienteVeiculo, _dispositivos));
        })

        // _clienteVeiculosRow = _clienteVeiculosRow.filter((linha : iClienteVeiculo)=>{return (linha.idcliente_veiculo===1)})

        // _clienteVeiculosRow = _clienteVeiculosRow.filter((linha : iClienteVeiculo)=>{return (linha.idcliente_veiculo===1)})

        setClienteVeiculosRow(_clienteVeiculosRow)
      } else {
        alert('nao ha veículos para instalar')
      }
    })
  }

  function buscaDadosTabelaPreco() {
    api.get(`/tabelapreco/vigente`).then(res => {
      settabelaPrecoCompleta(res.data)

      console.log('tabela de preco compçeta', res.data)
      const _tabelapreco: Array<iSelect> = res.data.map(
        (value: any, index: any) => {
          return {
            value: value.idtabela_preco.toString(),
            label: value.nmtabela_preco
          }
        }
      )

      setDados({ id: 'idtabela_preco', value: _tabelapreco[0].value })

      settabelaPreco(_tabelapreco)
    })
  }

  function buscaDadosTipoPagamentoInstalacao() {
    tabelaCodigoService
      .getChave('tipo_pagamento_instalacao')
      .then((res: any) => {
        console.log('tabela de codigo -get.tipo instalacao', res)
        const _tc: Array<iSelect> = res.map((value: any, index: any) => {
          return { value: value.tcchave, label: value.txvalor }
        })
        settipoPagamentoInstalacao(_tc)
      })
  }

  function buscaDadosTipoPagamentoMensalidade() {
    tabelaCodigoService
      .getChave('tipo_pagamento_mensalidade')
      .then((res: any) => {
        console.log('tabela de codigo -get.tipo instalacao', res)
        const _tc: Array<iSelect> = res.map((value: any, index: any) => {
          return { value: value.tcchave, label: value.txvalor }
        })
        settipoPagamentoMensalidade(_tc)
      })
  }

  function buscaDadosInstalador() {
    api.get(`/instaladores`).then(res => {
      console.log('buscaDadosinstalador-get.instalador', res.data[0])
      const _instalador: Array<iSelect> = res.data.map(
        (value: any, index: any) => {
          return {
            value: value.idinstalador.toString(),
            label: value.nminstalador
          }
        }
      )

      console.log('instalador pronto: ', _instalador)

      setInstaladores(_instalador)
    })
  }

  function buscaDispositivoDisponivel() {
    api.get(`/dispositivo/disponivel`).then(res => {
      console.log('buscadados-dispositivo-get.dispositivo', res.data[0])
      const _dispositivos: Array<iSelect> = res.data.map(
        (value: any, index: any) => {
          return { value: value.iddispositivo.toString(), label: value.nrimei }
        }
      )

      console.log('dispositivo pronto: ', _dispositivos)

      setdispositivoDisponivel(_dispositivos)
      buscaDadosVeiculo(_dispositivos)
    })
  }

  function handleSaveCliente() {
    const saveCliente = {
      ...dadosCliente,
      cliente_endereco: { ...dadosEndereco },
      cliente_veiculos: [...clienteVeiculos]
    }

    console.log('gravar dados')
    console.log(dadosCliente)

    if (modoTela === 'EDIT') {
      api
        .patch('/cliente', saveCliente)
        .then(res => {
          const data = res.data
          if (!data.error) {
            setDialogOpen(false)
            alert(data.message)
            window.location.reload()
          } else {
            alert(data.message)
          }
        })
        .catch(e => {
          alert('Ocorreu um erro ao atualizar o cliente')
        })
    } else {
      api
        .post('cliente', saveCliente)
        .then(res => {
          alert('Cliente salvo com sucesso!')
          navigation.goBack()
        })
        .catch(e => {
          alert('Ocorreu um erro ao salvar o cliente')
        })
    }
  }

  function handleInstalar() {
    const _instalar = {}

    console.log('gravar dados')
    console.log(dadosCliente)

    // if (modoTela === "EDIT") {
    //   api.patch("/cliente", saveCliente).then((res) => {
    //     let data = res.data;
    //     if (!data.error) {
    //       setDialogOpen(false);
    //       alert(data.message);
    //       window.location.reload();
    //     } else {
    //       alert(data.message);
    //     }
    //   }).catch((e) => {
    //     alert("Ocorreu um erro ao atualizar o cliente");
    //   })
    // } else {
    // api.post("cliente", saveCliente).then((res) => {
    //   alert("Cliente salvo com sucesso!");
    //   navigation.goBack()
    // }).catch((e) => {
    //   alert("Ocorreu um erro ao salvar o cliente");
    // });
    // }
  }

  function trocaDadosCliente(a: any) {
    const obj: iv1OperadorCliente = { ...dadosCliente, [a.id]: a.value }
    setDadosCliente(obj)
  }

  function setDados(a: any) {
    console.log('----------------set dados -----------------')

    function setDados(a: any) {
      const obj: iOperacaoInstalacao = {
        ...operacaoInstalacao,
        [a.id]: a.value
      }
      const ajustavalor = ['vldesconto', 'idtabela_preco']

      console.log('----------------set dados -----------------', obj)
      // console.log(a.value)
      // obj.dtinstalacao = '2022-01-10'

      if (ajustavalor.includes(a.id) || !obj.vldesconto) {
        if (!obj.vldesconto) obj.vldesconto = 0
        obj.vltabela_preco = pegaValorTabela(obj.idtabela_preco)
        obj.vlfinal = obj.vltabela_preco - obj.vldesconto
      }
      setoperacaoInstalacao(obj)
    }

    function pegaValorTabela(id: number) {
      console.log(
        'pega valor tabela',
        tabelaPrecoCompleta,
        id,
        operacaoInstalacao.idtabela_preco
      )
      const i = tabelaPrecoCompleta.findIndex(value => {
        return value.idtabela_preco === operacaoInstalacao.idtabela_preco
      })
      if (i && i >= 0)
        return tabelaPrecoCompleta[i].tabela_preco_item.vlinstalacao
      else return 0
    }

    function trocaDadosEndereco(a: any) {
      const obj: iClienteEndereco = {
        ...dadosEndereco,
        [a.id]: a.value
      } as iClienteEndereco
      setDadosEndereco(obj)
    }

    function removeCarRow(rowId: string) {
      console.log(clienteVeiculosRow)
    }

    /*

                    <Select
                    label="Dispositivo"
                    placeholder="Dispositivo"
                    value={operacaoInstalacao.iddispositivo}
                    name="iddispositivo"
                    options={dispositivoDisponivel}
                    onChange={(e)=>{setDados(e.target)}}
                  />




function makeCarRow(car: iClienteVeiculoCliente, _dispositivos: iSelect[]) {
  const rowId = (clienteVeiculosRow.length + 1).toString()

  console.log('makeCarRow', _dispositivos)

  const _dd = [...dispositivoDisponivel]
  const select = (
    <div>
      <Select
        label="Dispositivo"
        placeholder="Dispositivo"
        value={operacaoInstalacao.iddispositivo}
        name="iddispositivo"
        options={_dispositivos}
        onChange={e => {
          setDados(e.target)
        }}
      />
    </div>
  )
  const botaoInstalar = (
    <div>
      <Button to={`/cliente/edit/`}>
        <FaEdit />
      </Button>
    </div>
  )
  const row: iRow = {
    rowId: rowId,
    values: [
      car.cliente.txnome_cliente,
      maskPlaca(car.nrplaca),
      car.txmarca,
      car.txmodelo,
      car.nrano,
      car.txcor,
      select,
      botaoInstalar
    ]
  }

  return row
}
  }
return (
  <div id="page-cliente">
    <PageHeader></PageHeader>
    <Sidebar></Sidebar>
    <div className="container">
      <div className="content">
        <div className="content">
          <h2>Instalação</h2>

          <div className="bloco">
            <div className="subtitle">
              <h3>Datas</h3>
              <hr></hr>
            </div>

            <div className="row addCarFields">
              <Input
                label="Data da Instalação"
                placeholder="..."
                type="date"
                value={operacaoInstalacao.dtinstalacao}
                name="dtinstalacao"
                onChange={e => {
                  setDados(e.target)
                }}
              />

              <Input
                label="Inicio do Rastreamento"
                placeholder="..."
                type="date"
                value={operacaoInstalacao.dtinicio_rastreamento}
                name="dtinicio_rasteramento"
                onChange={e => {
                  setDados(e.target)
                }}
              />
            </div>
          </div>
          <div className="bloco">
            <div className="subtitle">
              <h3>Instalação</h3>
              <hr></hr>
            </div>

            <div className="row addCarFields">
              <Select
                label="Instalador"
                name="idinstalador"
                placeholder="Selecione o instalador"
                value={operacaoInstalacao.idinstalador}
                options={instaladores!}
                onChange={e => {
                  setDados(e.target)
                }}
              />
            </div>
          </div>
          <div className="bloco">
            <div className="subtitle">
              <h3>Faturamento</h3>
              <hr></hr>
            </div>

            <div className="row addCarFields">
              <Select
                label="Tipo Pagto Instalação"
                placeholder="..."
                value={operacaoInstalacao.tctipo_pagamento_instalacao}
                name="tctipo_pagamento_instalacao"
                options={tipoPagamentoInstalacao}
                onChange={e => {
                  setDados(e.target)
                }}
              />

              <Select
                label="Tipo Pagto Mensalidade"
                placeholder="..."
                value={operacaoInstalacao.tctipo_pagamento_mensalidade}
                name="tctipo_pagamento_mensalidade"
                options={tipoPagamentoMensalidade}
                onChange={e => {
                  setDados(e.target)
                }}
              />

              <Select
                label="Tabela Preco"
                placeholder="..."
                value={operacaoInstalacao.idtabela_preco}
                name="idtabela_preco"
                options={tabelaPreco}
                onChange={e => {
                  setDados(e.target)
                }}
              />
            </div>

            <div className="bloco">
              <div className="subtitle">
                <h3>Valores</h3>
              </div>
              <hr></hr>
              <div className="row addCarFields">
                <Input
                  label="Valor Tabela"
                  type="number"
                  disabled={true}
                  value={operacaoInstalacao.vltabela_preco}
                  name="vltabela_preco"
                  onChange={e => {
                    setDados(e.target)
                  }}
                />

                <Input
                  label="Desconto"
                  placeholder="valor"
                  type="number"
                  value={operacaoInstalacao.vldesconto}
                  name="vldesconto"
                  onChange={e => {
                    setDados(e.target)
                  }}
                />
                <Input
                  label="Valor Final"
                  type="number"
                  disabled={true}
                  value={operacaoInstalacao.vlfinal}
                  name="vlfinal"
                  onChange={e => {
                    setDados(e.target)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bloco">
          <div className="row button-controls">
            <Button
              onClick={
                modoTela === 'EDIT'
                  ? () => {
                    setDialogOpen(true)
                  }
                  : handleSaveCliente
              }
            >
              Salvar
            </Button>
            <Button
              onClick={
                modoTela === 'EDIT'
                  ? () => {
                    setDialogOpen(true)
                  }
                  : handleInstalar
              }
            >
              Instalar
            </Button>
            <Button
              onClick={() => {
                navigation.goBack()
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    </div>

    <h2>Veículos</h2>
    <div className="bloco">
      <div className="bloco">
        <Table
          customClass="table-cliente-veiculo"
          labels={[
            'Cliente',
            'Placa',
            'Marca',
            'Modelo',
            'Ano',
            'Cor',
            'Dispositivo',
            'Instalar'
          ]}
          data={clienteVeiculosRow}
          key={'tabela'}
          name="table-veiculos-cliente"
          loading={
            modoTela == 'EDIT' && clienteVeiculosRow.length == 0
              ? true
              : false
          }
          qtloading={3}
        />
      </div>
    </div>

    <Dialog
      title="Atenção!"
      text="Tem certeza que deseja salvar ?"
      isOpen={dialogOpen}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      buttons={[
        {
          text: 'Não',
          type: 'cancel'
        },
        {
          text: 'Sim',
          type: 'button',
          onClick: handleSaveCliente
        }
      ]}
      onRequestClose={() => {
        setDialogOpen(false)
      }}
    ></Dialog>
  </div>
)

} */

export default Instalacao;
