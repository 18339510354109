import React, { useContext, useEffect, useState } from 'react'

import InfoBox from '../../components/InfoBox'
import PageHeader from '../../components/PageHeader'
import Sidebar from '../../components/Sidebar'
// import InfiniteScroll from 'react-infinite-scroller';
// import InfiniteScroll from 'react-infinite-scroll-component'

import './styles.css'
import api from '../../services/api'

import { AxisOptions, Chart } from 'react-charts'
import { FaCalendar, FaMoneyBill } from 'react-icons/fa'

import { Table, iRow } from 'components/Table'
import moment from 'moment'
import AuthContext from 'contexts/auth'

interface iPeriodoChart {
  nrperiodo: string
  vlprevisto: number
  vlfaturado: number
}

function Home() {
  const [titulosAbertos, setTitulosAbertos] = useState<iRow[]>([]);
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if(user) {
      buscaDadosGrafico()
      buscaTitulosAbertos();
    }
  }, [user])


  const [faturamentoChart, setFaturamentoChart] = useState(
    montaRetorno(new Date())
  )
  const [faturamentoPrevistoChart, setFaturamentoPrevistoChart] = useState(
    montaRetorno(new Date())
  )
  const [periodoChart, setPeriodoChart] = useState<iPeriodoChart>({
    nrperiodo: '',
    vlprevisto: 0,
    vlfaturado: 0
  } as iPeriodoChart)

  function montaRetorno(date: Date): Array<FaturamentoData> {
    const months = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12'
    ]
    const year = date.getFullYear()

    return months.map((month, index) => {
      return { date: `${year}/${month}`, value: 0 }
    })
  }

  function buscaDadosGrafico() {
    setTimeout(() => {
      api
        .get('/home/charts/faturamento')
        .then(res => {
          const data = res.data as Array<{
            nrperiodo: string
            vlprevisto: number
            vlfaturado: number
          }>
          const _faturamentoPrevisto = [...faturamentoPrevistoChart]
          const _faturamento = [...faturamentoChart]

          data.map((value, index) => {
            const a = _faturamento.findIndex(item => {
              return item.date.replace('/', '') == value.nrperiodo
            })
            const b = _faturamentoPrevisto.findIndex(item => {
              return item.date.replace('/', '') == value.nrperiodo
            })
            _faturamento[a].value = value.vlfaturado
            _faturamentoPrevisto[b].value = value.vlprevisto
          })

          console.log(_faturamento)

          // ret = ret.map((item, index) => {
          //     return { ...item, value: Math.ceil(Math.random() * 1000) }
          // })

          // setFaturamentoChart(res.data);
        })
        .catch(e => {
          console.log(e)
          alert('Ocorreu um erro ao buscar os dados do faturamento')
        })
      api
        .get('/home/charts/periodo')
        .then(res => {
          setPeriodoChart(res.data)
        })
        .catch(e => {
          alert('Ocorreu um erro ao buscar os dados do periodo')
        })
    }, 900)
  }

  function buscaTitulosAbertos() {
    setTimeout(() =>{
    api.get("/titulos/receber/emaberto", {
      params: {
        periodo: moment().format("YYYYMM")
      }
    }).then((res) => {
      const data = res.data
      console.log("data titulos")
      console.log(data)
      if (data) {
        const moneyFormat = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }
        const rows: iRow[] = data.map((titulo) => {
          return {
            rowid: titulo.idtitulo_receber,
            values: [
              titulo.idtitulo_receber,
              `${titulo.cliente.txnome_cliente} ${titulo.cliente.txsobrenome_cliente || ''}`,
              moment(titulo.nrperiodo_referencia + "01").format("MM/YYYY"),
              moment(titulo.dtvencimento).format("DD/MM/YYYY"),
              titulo.vltitulo.toLocaleString('pt-BR', moneyFormat)
            ]
          }
        })
        setTitulosAbertos(rows)
      }
    }).catch((e) => {
      alert("Ocorreu um erro buscando os titulos em aberto")
    })}, 1000)
  }

  type FaturamentoData = {
    date: string
    value: number
  }

  type FaturamentoChart = {
    label: string
    data: FaturamentoData[]
  }

  const data: FaturamentoChart[] = [
    {
      label: 'Recebido',
      data: faturamentoChart
    },
    {
      label: 'Faturado',
      data: faturamentoPrevistoChart
    }
  ]

  const primaryAxis = React.useMemo(
    (): AxisOptions<any> => ({
      getValue: datum => datum.date,
      elementType: 'line'
    }),
    []
  )

  const secondaryAxes = React.useMemo(
    (): AxisOptions<any>[] => [
      {
        getValue: datum => datum.value,
        formatters: {
          tooltip: (value: number) =>
            value?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL'
            })
        },
        elementType: 'line'
      }
    ],
    []
  )

  return (
    <div id="page-home">
      <PageHeader />
      <Sidebar />
      <div className="container">
        <div className="content">
          <div className="periodoAtual-chart">
            <InfoBox
              icon={{
                icon: "FaCalendar",
                color: 'white',
                backgroundColor:
                  'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))'
              }}
              text={{
                title: 'Periodo atual',
                subtitle: periodoChart.nrperiodo
                  ? `${periodoChart.nrperiodo?.substring(
                    0,
                    4
                  )}/${periodoChart.nrperiodo?.substring(4, 6)}`
                  : ''
              }}
            />

            <InfoBox
              icon={{
                icon: "FaMoneyBill",
                color: 'white',
                backgroundColor:
                  'linear-gradient(195deg, rgb(251, 140, 0), rgb(221 124 2))'
              }}
              text={{
                title: 'Valor faturado',
                subtitle: periodoChart.vlprevisto?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })
              }}
            />
            <InfoBox
              icon={{
                icon: "FaMoneyBill",
                color: 'white',
                backgroundColor:
                  'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))'
              }}
              text={{
                title: 'Valor recebido atual',
                subtitle: periodoChart.vlfaturado?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })
              }}
            />
          </div>
          <div className="home-chart">
            <div style={{ width: '100%', height: '100%' }}>
              <Chart
                options={{
                  data,
                  primaryAxis,
                  secondaryAxes
                }}
              />
            </div>
          </div>
          <div className='titulos-box'>
            <h3>Titulos em Aberto Periodo: {moment().format("MM/YYYY")}</h3>
            <div>
              <Table name='table-titulos'
                data={titulosAbertos}
                labels={["#", "Cliente", "Periodo", "Vencimento", "Valor"]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
