import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faCalculator,
//   faDollarSign,
//   faDoorOpen,
//   faLocationArrow,
//   faSimCard
// } from '@fortawesome/free-solid-svg-icons'
// import { faUsers, faCar } from '@fortawesome/free-solid-svg-icons'
import logoWhite from '../../assets/images/logo_white.svg'
import AuthContext from '../../contexts/auth'

import './styles.css'
import {
  FaCalculator,
  FaCar,
  FaDollarSign,
  FaDoorOpen,
  FaLocationArrow,
  FaSimCard,
  FaUsers
} from 'react-icons/fa'

const Sidebar = () => {
  const history = useHistory()
  const { user, signOut } = useContext(AuthContext)

  async function handleLogout() {
    await signOut()
    history.push('/')
  }
  return (
    <aside id="sidebar">
      <div className="logo">
        <Link to="/">
          <img src={logoWhite} alt="RadarSystem" />
        </Link>
      </div>
      <nav>
        <div className="navigation">
          <ul>
            {/* {user?.tctipo_operador == 'admin' ? <li><Link to="/operador"><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon> Operadores</Link></li> : null} */}
            {user?.tctipo_operador == 'admin' ? (
              <li>
                <Link to={'/cliente/'}>
                  <FaUsers /> <i>Clientes</i>
                </Link>
              </li>
            ) : null}
            {user?.tctipo_operador == 'admin' ? (
              <li>
                <Link to={'/operador/edit/' + user?.idoperador}>
                  <FaUsers /> <i>Meus Dados</i>
                </Link>
              </li>
            ) : null}
            {user?.tctipo_operador == 'admin' ? (
              <li>
                <Link to="/chips">
                  <FaSimCard /> <i>Chips</i>
                </Link>
              </li>
            ) : null}
            {user?.tctipo_operador == 'admin' ? (
              <li>
                <Link to="/dispositivos">
                  <FaLocationArrow /> <i>Dispositivos</i>
                </Link>
              </li>
            ) : null}
            {user?.tctipo_operador == 'admin' ? (
              <li>
                <Link to="/veiculos">
                  <FaCar /> <i>Veículos</i>
                </Link>
              </li>
            ) : null}
            {user?.tctipo_operador == 'admin' ? (
              <li>
                <Link to="/operacaoInstalacao">
                  <FaCar /> <i>Instalação Veículos</i>
                </Link>
              </li>
            ) : null}
            {user?.tctipo_operador == 'admin' ? (
              <li>
                <Link to="/financeiro">
                  <FaDollarSign /> <i>Financeiro</i>
                </Link>
              </li>
            ) : null}
            {user?.tctipo_operador == 'admin' ? (
              <li>
                <Link to="/faturamento">
                  <FaCalculator /> <i>Faturamento</i>
                </Link>
              </li>
            ) : null}

            <li>
              <a onClick={handleLogout}>
                <FaDoorOpen /> Sair
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </aside>
  )
}

export default Sidebar
