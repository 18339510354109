import React, { useEffect, useState } from 'react'
import { FaEdit, FaPlus } from 'react-icons/fa'
import Button from '../../components/Button'
import PageHeader from '../../components/PageHeader'
import Sidebar from '../../components/Sidebar'
import { iRow, Table } from '../../components/Table'
import { iDispositivo } from '../../interfaces/interfaces'
import api from '../../services/api'
import { maskFone } from '../../services/utils'

import './styles.css'

function Dispositivos() {
  const [dispositivos, setDispositivos] = useState<Array<iRow>>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  useEffect(() => {
    getData()
    return
  }, [])

  function getData() {
    api
      .get('/dispositivos')
      .then(res => {
        const data = res.data as Array<iDispositivo>
        const _devices: Array<iRow> = data.map((value, index) => {
          return {
            rowId: value.iddispositivo.toString(),
            values: [
              value.nrimei,
              value.chip?.nrfone ? maskFone(value.chip?.nrfone) : 'LIVRE',
              `${value.tctipo_dispositivo}(${value.nrporta_sistema})`,
              value.dtcompra,
              value.vlpago,
              value.dtinativacao &&
              new Date(value.dtinativacao).toLocaleDateString(),
              value.txobservacao,
              <div key={value.iddispositivo.toString()}>
                <Button to={`dispositivo/edit/${value.iddispositivo}`}>
                  <FaEdit />
                </Button>
              </div>
            ]
          }
        })
        setDispositivos(_devices)
      })
      .catch(e => {
        console.log(e)
        alert('Ocorreu um erro ao bucar os dispositivos')
      })
  }

  return (
    <div id="page-dispositivos">
      <PageHeader />
      <Sidebar />
      <div className="content">
        <div className="bloco">
          <div className="buttons-container">
            <Button to="/dispositivos/create">
              <FaPlus />
              Novo
            </Button>
            {/* <a href="/operador/edit/-1"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>Novo</a> */}
          </div>
        </div>
        <div className="bloco">
          <Table
            labels={[
              'IMEI',
              'Numero',
              'Modelo',
              'Data Compra',
              'Valor Pago',
              'Data Inativação',
              'Observações',
              'Ações'
            ]}
            data={dispositivos}
            customClass="table-dispositivos"
            name="table-dispositivos"
            qtloading={4}
            loading={tableLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default Dispositivos
