export function getBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.result) {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        resolve(encoded)
      }
    }
    reader.onerror = error => reject(error)
  })
}

export const validaCPF = (cpf: string) => {
  cpf = cpf.replaceAll('.', '').replaceAll('-', '')
  console.log('cpf')
  console.log(cpf)
  let Soma
  let Resto
  Soma = 0
  if (cpf == '00000000000') return false
  if (cpf == '11111111111') return false
  if (cpf == '22222222222') return false
  if (cpf == '33333333333') return false
  if (cpf == '44444444444') return false
  if (cpf == '55555555555') return false
  if (cpf == '66666666666') return false
  if (cpf == '77777777777') return false
  if (cpf == '88888888888') return false
  if (cpf == '99999999999') return false

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0
  if (Resto != parseInt(cpf.substring(9, 10))) return false

  Soma = 0
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0
  if (Resto != parseInt(cpf.substring(10, 11))) return false
  return true
}

export const validaTelefone = (telefone: string) => {
  const fone = telefone
    .replace('(', '')
    .replace(')', '')
    .replace('.', '')
    .replace('-', '')
    .replace(' ', '')
  if (fone.length != 11) {
    return false
  }
  return true
}

export const maskFone = (fone: string) => {
  const str = fone
    .replace('-', '')
    .replace('.', '')
    .replace('(', '')
    .replace(')', '')
    .replace('+', '')
  let newstr = ''
  newstr = `+${str.substring(0, 2)} (${str.substring(2, 4)}) ${str.substring(
    4,
    9
  )}-${str.substring(9, 13)}`
  // if (str.length > 7) {
  //   newstr = str.replace(/(\d{2})?(\d{5})/, "($1)$2-");
  // } else if (str.length > 2) {
  //   newstr = str.replace(/(\d{2})/, "($1)");
  // } else {
  //   newstr = str;
  // }
  return newstr
}

export const maskCreditCard = (cardNumber: string) => {
  const card = cardNumber.replace(' ', '')

  const ret = card.match(/\d{1,4}/g)
  return ret?.join(' ') || ''
}

export const maskCreditCardValid = (date: string) => {
  let newStr
  date = date.replace('/', '')
  if (date.length > 2) {
    newStr = date.replace(/(\d{2})/, '$1/')
  } else {
    newStr = date
  }
  return newStr
}

export const maskCPF = (cpf: string) => {
  const str = cpf.replace('.', '').replace('.', '').replace('-', '')
  let newStr = ''
  // console.log(str.length, str);
  if (str.length < 4) {
    // console.log('...r1');
    newStr = str
  } else if (str.length < 7) {
    // console.log('...r2');
    newStr = str.replace(/(\d{3})?/, '$1.')
  } else if (str.length < 10) {
    // console.log('...r3');
    newStr = str.replace(/(\d{3})?(\d{3})?/, '$1.$2.')
  } else {
    // console.log('...r4');
    newStr = str.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4')
  }
  return newStr
}

export const maskCNPJ = (cnpj: string) => {
  const str = cnpj
    .replace('.', '')
    .replace('.', '')
    .replace('-', '')
    .replace('/', '')
  let newStr = ''
  if (str.length < 3) {
    newStr = str
  } else if (str.length < 6) {
    // console.log('...r2');
    newStr = str.replace(/(\d{2})?/, '$1.')
  } else if (str.length < 9) {
    // console.log('...r3');
    newStr = str.replace(/(\d{2})?(\d{3})?/, '$1.$2.')
  } else if (str.length < 13) {
    newStr = str.replace(/(\d{2})?(\d{3})?(\d{3})?(\d{4})?/, '$1.$2.$3/$4')
  } else {
    // console.log('...r4');
    newStr = str.replace(
      /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
      '$1.$2.$3/$4-$5'
    )
  }
  return newStr
}

export function maskCEP(cep: string) {
  const str = cep.replace('-', '')
  let newStr = ''
  if (str.length < 6) {
    newStr = str
  } else {
    newStr = str.replace(/(\d{5})?(\d{3})?/, '$1-$2')
  }
  return newStr
}

export function maskPlaca(placa: string) {
  let newStr = ''
  let str = ''
  if (placa) {
    str = placa.replace('-', '')
    if (str.length < 4) {
      newStr = str
    } else {
      newStr = str.replace(/(\w{3})?(\d{4})?/, '$1-$2')
    }
  }

  return newStr.toUpperCase()
}

export function hourToMinute(hour: string) {
  const _hour = hour.split(':')
  const _minutes = parseInt(_hour[0]) * 60 + parseInt(_hour[1])
  return _minutes
}

export function minuteToHour(minute: number) {
  const _hour = Math.floor(minute / 60)
  const _minute = minute - _hour * 60
  return ('00' + _hour).slice(-2) + ':' + ('00' + _minute).slice(-2)
}

export function getMinuteNow() {
  const dt = new Date()
  return dt.getHours() * 60 + dt.getMinutes()
}

export const validaEmail = (email: string) => {
  const mailformat = /\S+@\S+\.\S+/
  if (!mailformat.test(email)) {
    return false
  }
  return true
}

export const maskPeriodo = (nrperiodo: string | number) => {
  let newstr = ''
  nrperiodo = nrperiodo.toString()
  newstr = `${nrperiodo.substr(0, 4)}/${nrperiodo.substr(4, 2)}`
  return newstr
}
