import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Input from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import Sidebar from '../../components/Sidebar'
import api from '../../services/api'

import './styles.css'
import { Table, iRow } from '../../components/Table'

import { FaEdit, FaEraser, FaPlus } from 'react-icons/fa'

interface iOperador {
  idoperador: number
  cdoperador: string
  nmoperador: string
  tpoperador: number
  cdsenha: string
  cdavatar: string
  dtcadastro: Date
  txemail: string
}

function Operador() {
  const params = { filtro: {} }

  const [rowsOperador, setRowsOperador] = useState<Array<iRow>>()
  const tipoOperador = ['Admin', 'Prestador', 'Secretária']

  const [filtroNome, setfiltroNome] = useState('')
  const [loading, setLoading] = useState(true)

  const [removido, setremovido] = useState(true)

  function remove(aidoperador: number) {
    api
      .delete('/operador/' + aidoperador)
      .then(res => {
        alert('Operador removido com sucesso ')
        setremovido(!removido)
      })
      .catch(e => {
        alert(e)
      })
  }

  useEffect(() => {
    loadFunc()
  }, [filtroNome, removido])

  function loadFunc() {
    params.filtro = [
      {
        campo: 'nmoperador',
        valor: filtroNome,
        operacao: 'like'
      }
    ]
    api
      .post('/operador/', params)
      .then(res => {
        if (res.data.mensagem === 'Erro na busca: operador') {
          alert(res.data.mensagem)
          return
        }

        console.log(res.data)
        const data: Array<iRow> = []
        res.data.map((value: any, index: number) => {
          data.push({
            rowId: value.idoperador,
            values: [
              value.cdoperador,
              value.nmoperador,
              value.tctipo_operador,
              value.txemail,
              new Date(value.dtcadastro).toLocaleDateString('pt-br'),
              <div key={index} className="buttons-grid">
                <Link to={'/operador/edit/' + value.idoperador}>
                  <FaEdit />
                </Link>
                <a
                  onClick={() => {
                    remove(value.idoperador)
                  }}
                >
                  <FaEraser />
                </a>
              </div>
            ]
          })
        })

        // var data = operadores || [];
        // data = res.data;
        setRowsOperador(data)
        // setLoading(false);
      })
      .catch(e => {
        setLoading(false)
        const error = e.response
        alert('errrro')
        //alert(error.data.mensagem + " / " + error.data.erroBD) ;
      })
  }

  return (
    <div id="page-operador">
      <PageHeader></PageHeader>
      <Sidebar></Sidebar>

      <div className="container">
        <div className="content">
          <div className="bloco">
            <div className="buttons-container">
              <a href="/operador/edit/-1">
                <FaPlus />
                Novo
              </a>
            </div>
          </div>

          <div className="bloco">
            <Input
              type="text"
              label="Filtrar"
              placeholder="digite o nome do operador"
              name="filtro"
              value={filtroNome}
              onChange={e => {
                setfiltroNome(e.target.value)
              }}
            />
          </div>

          <div className="bloco">
            <Table
              labels={[
                'Codigo Operador',
                'Nome Operador',
                'Tipo Operador',
                'Email',
                'Data Cadastro',
                'Ações'
              ]}
              data={rowsOperador || []}
              name="operador-table"
            />
            {/* <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Codigo Operador</th>
                                    <th>Nome Operador</th>
                                    <th>Tipo Operador</th>
                                    <th>Email</th>
                                    <th>Data Cadastro</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(loading) ?
                                    <React.Fragment>
                                        <tr>
                                            <td><Skeleton height={28} width={50} /></td>
                                            <td><Skeleton height={28} width={80} /></td>
                                            <td><Skeleton height={28} width={50} /></td>
                                            <td><Skeleton height={28} width={40} /></td>
                                            <td><Skeleton height={28} width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={28} width={50} /></td>
                                            <td><Skeleton height={28} width={80} /></td>
                                            <td><Skeleton height={28} width={50} /></td>
                                            <td><Skeleton height={28} width={40} /></td>
                                            <td><Skeleton height={28} width={100} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={28} width={50} /></td>
                                            <td><Skeleton height={28} width={80} /></td>
                                            <td><Skeleton height={28} width={50} /></td>
                                            <td><Skeleton height={28} width={40} /></td>
                                            <td><Skeleton height={28} width={100} /></td>
                                        </tr>
                                    </React.Fragment>
                                    : null}

                                {operadores?.map((operador: iOperador, index) => {
                                    return (<tr key={operador?.idoperador}>
                                        <td className="avatarLista"><img src={operador.cdavatar || placeholder} /> </td>
                                        <td>{operador?.cdoperador}</td>
                                        <td>{operador?.nmoperador}</td>
                                        <td>{tipoOperador[operador?.tpoperador]}</td>
                                        <td>{operador?.txemail}</td>
                                        <td>{new Date(operador?.dtcadastro).toLocaleDateString('pt-br')}</td>
                                        <td className="buttons-grid">
                                            <Link to={'/operador/edit/' + operador?.idoperador}  ><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></Link>
                                            <div className="buttons-grid">
                                                <a onClick={() => { remove(operador.idoperador) }}><FontAwesomeIcon icon={faEraser}></FontAwesomeIcon></a>
                                            </div>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Operador
