import React from 'react'
import logoWhite from '../../assets/images/logo_white.svg'
import { Link } from 'react-router-dom'

import './styles.css'

const PageHeader = () => {
  return (
    <div id="pageHeader">
      <header>
        <div className="header-content">
          <div className="logo">
            <Link to="/">
              <img src={logoWhite} alt="RadarSystem" />
            </Link>
          </div>
        </div>
      </header>
    </div>
  )
}

export default PageHeader
