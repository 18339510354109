import React, { useContext } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import AuthContext from './contexts/auth'
import { iProps } from './interfaces/interfaces'
import Chips from './pages/Chips'
import ChipEdit from './pages/Chips/edit'
import Cliente from './pages/Cliente'
import ClienteEdit from './pages/Cliente/edit'
import Dispositivos from './pages/Dispositivos'
import DispositivosCreate from './pages/Dispositivos/create'
import Faturamento from './pages/Faturamento'
import Financeiro from './pages/Financeiro'
import FinanceiroDetalhe from './pages/FinanceiroDetalhe'
import ForgotPassword from './pages/ForgotPassword'
import Home from './pages/Home'
import Login from './pages/Login'
import Instalacao from './pages/Operacao/Instalacao'
import Operador from './pages/Operador'
import OperadorEdit from './pages/Operador/edit'
import PasswordReset from './pages/PasswordReset'

const CustomRoute: React.FC<iProps> = ({
  component,
  path,
  exact,
  tctipo_operador,
  ...rest
}) => {
  const { user } = useContext(AuthContext)
  function hasPermission() {
    if (tctipo_operador !== null) {
      if (tctipo_operador === user?.tctipo_operador) return true
      else return false
    } else {
      return true
    }
  }
  return hasPermission() ? (
    <Route path={path} exact component={component} />
  ) : (
    <Route path="*" component={() => <h1>Not authorized</h1>} />
  )
}

const Routes = () => {
  const { signed } = useContext(AuthContext)

  return signed ? (
    <BrowserRouter>
      <Switch>
        <CustomRoute
          exact
          path="/operador"
          component={Operador}
          tctipo_operador={'admin'}
        />
        <CustomRoute
          exact
          path="/cliente"
          component={Cliente}
          tctipo_operador={'admin'}
        />
        <CustomRoute
          exact
          path="/operador/edit/:aidoperador"
          component={OperadorEdit}
          tctipo_operador={'admin'}
        />
        <CustomRoute
          exact
          path="/cliente/edit/:idcliente"
          component={ClienteEdit}
          tctipo_operador={'admin'}
        />
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/financeiro" component={Financeiro} />
        <Route exact path="/financeiro/detalhe" component={FinanceiroDetalhe} />
        <Route exact path="/chips" component={Chips} />
        <CustomRoute
          exact
          path="/chips/edit/:idchip"
          component={ChipEdit}
          tctipo_operador={'admin'}
        />
        <Route exact path="/dispositivos" component={Dispositivos} />
        <Route
          exact
          path="/dispositivos/create"
          component={DispositivosCreate}
        />
        <Route
          exact
          path="/operacaoInstalacao"
          component={Instalacao}
        />
        <Route exact path="/faturamento" component={Faturamento} />
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/password-reset/:token" component={PasswordReset} />
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
