import { array } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FaCalculator } from 'react-icons/fa'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Input from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import Sidebar from '../../components/Sidebar'
import { iRow, Table } from '../../components/Table'
import api from '../../services/api'
import './styles.css'

// const Faturamento: React.FC = () => {
function Faturamento() {
  const dt = new Date()
  const meses = [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'des'
  ]

  const mesinicial = meses[dt.getMonth()]
  const [periodo, setperiodo] = useState(mesinicial)
  const [dadosTabela, setdadosTabela] = useState<Array<iRow>>([])

  useEffect(() => {
    const dadosCliente = [
      {
        nome: 'Fabiano',
        Id: 1,
        valor: 100
      },
      {
        nome: 'Paulo',
        Id: 2,
        valor: 300
      },
      {
        nome: 'Fabiano',
        Id: 1,
        valor: 100
      },
      {
        nome: 'Paulotomas',
        Id: 2,
        valor: 300
      },
      {
        nome: 'Paulo',
        Id: 2,
        valor: 300
      }
    ]

    const arraydd: Array<iRow> = []

    // dadosCliente.map((elemento: any) => {

    for (let i = 0; i < dadosCliente.length; i++) {
      const dd: iRow = {} as iRow

      const elemento = dadosCliente[i]

      dd.rowId = elemento.Id.toString()
      dd.values = [
        elemento.Id,
        elemento.nome,
        elemento.valor,
        <div key={elemento.Id} className="Check1">
          <Checkbox
            label=" "
            value=" "
            name=" "
          // checked={(tipoPessoa === "pessoaJuridica" ? true : false)}
          // onChange={e => {}}
          />
        </div>
      ]
      console.log(i, elemento, dd)
      arraydd.push(dd)
    }

    setdadosTabela(arraydd)
  }, [])

  return (
    <div id="page-dispositivos">
      <PageHeader />
      <Sidebar />
      <div>
        <div className="Box1">
          <Input
            label="Período"
            placeholder="Data"
            name="Data"
            value={periodo}
            // maxLength={2}
            //   value={dadosCliente?.nrdia_vencimento}
            onChange={e => {
              setperiodo(e.target.value)
              // trocaDadosCliente(e.target);
            }}
          />
          <div>
            <div className="Button99">
              <Button to="/cliente/edit/-1">
                {' '}
                <FaCalculator /> Calcular
              </Button>
            </div>
          </div>
        </div>
        <div className="Titulo1">
          <h4 className="Tittle1">Selecione os clientes que serão faturados</h4>
        </div>
        <div className="bloco">
          <div>
            <Table
              labels={[
                'ID',
                'Nome',
                'Valor',
                <div key={'a'}>
                  <Checkbox
                    label="Selecionar Todos"
                    value=" "
                    name=" "
                  // onChange={e => {}}
                  />
                </div>
              ]}
              data={dadosTabela}
              customClass="table-dispositivos"
              name="table-dispositivos"
              qtloading={4}
            // loading={tableLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Faturamento
