import React, { useEffect, useState } from 'react'
import { FaPlus, FaSave, FaTrash } from 'react-icons/fa'
import { useHistory } from 'react-router'
import Button from '../../components/Button'
import Dialog from '../../components/Dialog'
import Input from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import { Select } from '../../components/Select'
import Sidebar from '../../components/Sidebar'
import { iRow, Table } from '../../components/Table'
import Textarea from '../../components/TextArea'
import { iDispositivo } from '../../interfaces/interfaces'
import api from '../../services/api'

import './styles.css'

function DispositivosCreate() {
  const history = useHistory()
  const [listDispositivos, setListDispositivos] = useState<Array<iDispositivo>>(
    []
  )
  const [deviceRows, setDeviceRows] = useState<Array<iRow>>([])
  const [dispositivo, setDispositivo] = useState<iDispositivo>(
    {} as iDispositivo
  )
  const [fieldsErro, setFieldErros] = useState<any>({})
  const [isOpen, setIsOpen] = useState(false)
  const [preDeletedRow, setPreDeletedRow] = useState<string>('')

  useEffect(() => {
    console.log(dispositivo)
  }, [dispositivo])

  function handleValueChange(e: any) {
    setFieldErros({ ...fieldsErro, [e.target.name]: false })
    setDispositivo({ ...dispositivo, [e.target.name]: e.target.value })
  }

  function validateFields() {
    let ret = false
    const _fieldsErro: { [key: string]: any } = {
      tctipo_dispositivo: true,
      nrporta_sistema: true,
      dtcompra: true,
      vlpago: true
    }
    Object.keys(dispositivo).map(value => {
      _fieldsErro[value] = !dispositivo[value]
    })

    const _ret = Object.keys(_fieldsErro).filter(value => {
      return _fieldsErro[value] === true
    })
    ret = _ret.length === 0 ? true : false
    setFieldErros(_fieldsErro)

    return ret
  }

  function removeRow(rowid: string) {
    const _deviceRows: Array<iRow> = [...deviceRows]
    _deviceRows.splice(
      _deviceRows.findIndex(item => item.rowId === rowid),
      1
    )
    setDeviceRows(_deviceRows)

    const _listDispositivos: Array<iDispositivo> = [...listDispositivos]
    _listDispositivos.splice(
      _listDispositivos.findIndex(item => item.nrimei === rowid),
      1
    )
    setListDispositivos(_listDispositivos)

    setIsOpen(false)
  }

  function hadleRemoveDeviceRow(rowid: string) {
    setIsOpen(true)
    setPreDeletedRow(rowid)
  }

  function handleAddDevice(nrimei: string) {
    const imei = nrimei.trim()
    const _dispositivo = { ...dispositivo }
    _dispositivo.nrimei = imei
    _dispositivo.nrfabrica = imei
    if (validateFields()) {
      const findImei = deviceRows.filter(value => {
        return value.rowId === imei
      })

      if (findImei.length === 0 || deviceRows.length === 0) {
        // if (true) {
        const deviceRow: iRow = {
          rowId: imei,
          values: [
            dispositivo.tctipo_dispositivo,
            imei,
            dispositivo.dtcompra,
            dispositivo.vlpago,
            dispositivo.nrporta_sistema,
            <div key={imei}>
              <Button
                onClick={() => {
                  hadleRemoveDeviceRow(imei)
                }}
              >
                <FaTrash />
              </Button>
            </div>
          ]
        }

        const _listDispositivos = [...listDispositivos]
        _listDispositivos.push(_dispositivo)
        setListDispositivos(_listDispositivos)

        const _deviceRows = [...deviceRows]
        _deviceRows.push(deviceRow)
        setDeviceRows(_deviceRows)
      }
    }
  }

  function handleSaveDevices() {
    if (listDispositivos.length > 0) {
      api
        .post('/dispositivo/create', { dispositivos: listDispositivos })
        .then(res => {
          alert(res.data.message)
          history.goBack()
        })
        .catch(e => {
          console.log(e)
          alert(e.response.data.message)
        })
    } else {
      alert('Adicione pelo menos 1 dispositivo!')
    }
  }

  return (
    <div id="page-dispositivos-create">
      <PageHeader />
      <Sidebar />
      <div className="content">
        <div className="bloco">
          <div className="intructions">
            <p>
              Para uma inserção em massa, selecione aqui as opções comuns de
              cada dispositivo
            </p>
          </div>
          <div className="row">
            <Select
              label="Modelo"
              name="tctipo_dispositivo"
              placeholder="Modelo"
              invalid={fieldsErro.tctipo_dispositivo}
              value={dispositivo.tctipo_dispositivo}
              options={[
                { label: 'GT06', value: 'GT06' },
                { label: 'TK303', value: 'TK303' },
                { label: 'J16 4G', value: 'j16'}
              ]}
              onChange={handleValueChange}
            />
            <Input
              label="Porta do Sistema"
              name="nrporta_sistema"
              invalid={fieldsErro.nrporta_sistema}
              placeholder="Porta Servidor"
              value={dispositivo.nrporta_sistema}
              onChange={handleValueChange}
            />
            {/* </div> */}
            {/* <div className="row"> */}
            <Input
              label="Data de Compra"
              type="date"
              name="dtcompra"
              invalid={fieldsErro.dtcompra}
              placeholder="Data Compra"
              value={dispositivo.dtcompra}
              onChange={handleValueChange}
            />
            <Input
              label="Valor Pago"
              name="vlpago"
              invalid={fieldsErro.vlpago}
              placeholder="Valor Pago"
              value={dispositivo.vlpago}
              onChange={e => {
                setFieldErros({ ...fieldsErro, [e.target.name]: false })
                setDispositivo({
                  ...dispositivo,
                  [e.target.name]: e.target.value.replace(',', '.')
                })
              }}
            />
          </div>
        </div>
        <div className="bloco">
          <div className="intructions">
            <p>Digite o imei do dispositivo e clique em adicionar</p>
            <p>
              Ou clique no campo e utilize um leitor de codigo de barras para
              adicionar varios dispositivos facilmente
            </p>
          </div>
          <div className="row">
            {/* <textarea></textarea> */}
            <Textarea
              style={{ resize: 'none' }}
              label=""
              name="nrimei"
              className="imeitextarea"
              rows={1}
              placeholder="Numero IMEI"
              value={dispositivo.nrimei}
              onChange={e => {
                if (
                  e.target.value.includes('\n') &&
                  !e.target.value.includes(' ')
                ) {
                  handleAddDevice(e.target.value)
                }
                setTimeout(() => {
                  e.target.value = ''
                }, 200)
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end'
              }}
            >
              <Button>
                <FaPlus /> Adicionar
              </Button>
            </div>
          </div>
          <div>
            <b style={{ padding: '1rem' }}>
              Quantidade de registros: {listDispositivos.length}
            </b>
            <Table
              labels={[
                'Modelo',
                'IMEI',
                'Data Compra',
                'Valor Pago',
                'Porta Sistema',
                'Ações'
              ]}
              data={deviceRows}
              name="table-dispositivos"
            />
          </div>
        </div>
        <div className="bloco">
          <Button onClick={handleSaveDevices}>
            <FaSave /> Salvar
          </Button>
        </div>
      </div>
      <Dialog
        isOpen={isOpen}
        data={preDeletedRow}
        onRequestClose={() => {
          setIsOpen(false)
          setPreDeletedRow('')
        }}
        title="Tem certeza ?"
        text="Tem certeza que deseja remover o dispositivo ?"
        buttons={[
          { text: 'Não', type: 'cancel' },
          {
            text: 'Sim',
            type: 'button',
            onClick: data => {
              removeRow(data)
            }
          }
        ]}
      />
    </div>
  )
}

export default DispositivosCreate

/*
#campos comuns
#TIPO
#VLPAGO
#DTCOMPRA
#NRPORTA_SISTEMA

#campos_variaveis
#NRFABRICA
#NRIMEI

*/
