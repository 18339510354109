import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'

import AuthContext from '../contexts/auth'
import { getToken, logout } from './auth'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
  //  baseURL: "http://192.168.0.13:3333"
  //  baseURL: "http://192.168.0.13:3333"
  // baseURL: "https://47f5725a419a.ngrok.io"
  //baseURL : "http://192.168.0.20:3333"
  // baseURL : "http://177.125.32.122:8014/api/v1"
})

//autorizacoes/01320351000479000

api.interceptors.request.use(async config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `${token}`
  }
  return config
})

export default api
