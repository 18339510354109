import React, { Children, useState } from 'react'
import Modal from 'react-modal'
import Button from '../Button'
import './styles.css'

interface iDialog {
  title: string
  text: string
  data?: any
  isOpen: boolean
  onRequestClose(data?: any): void
  shouldCloseOnOverlayClick?: boolean
  shouldCloseOnEsc?: boolean
  buttons?: Array<iModalButton>
}

interface iModalButton {
  text: string
  type: 'button' | 'cancel'
  onClick?(data?: any): void
}

const Dialog: React.FC<iDialog> = ({
  isOpen,
  title,
  text,
  onRequestClose,
  buttons,
  data,
  ...rest
}) => {
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  }

  return (
    <Modal
      id="modal-x"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={modalStyle}
      {...rest}
    >
      <div className="modal-title">
        <p>{title}</p>
      </div>
      <div className="modal-body">
        <p>{text}</p>
      </div>
      <div className="modal-footer">
        <div className="controls">
          {buttons?.map((value, index) => {
            return (
              <Button
                key={index}
                className={value.type}
                onClick={
                  value.type === 'cancel'
                    ? onRequestClose
                    : () => {
                        if (value.onClick) value.onClick(data)
                      }
                }
              >
                {value.text}
              </Button>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export default Dialog
