import React, { createContext, useEffect, useState } from 'react'

import {
  iOperador,
  iReturn,
  AuthContextData,
  iLoginResponse
} from '../interfaces/interfaces'
import api from '../services/api'
import * as auth from '../services/auth'


const AuthContext = createContext<AuthContextData | null>({} as AuthContextData)

export const AuthProvider: React.FC<ChildrenContext> = ({ children }) => {
  const [user, setUser] = useState<iOperador | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    api.interceptors.response.use(
      response => {
        return response
      },
      async error => {
        if (401 === error.response.status) {
          signOut()
        } else {
          return Promise.reject(error)
        }
      }
    )
    async function loadStorageData() {
      const storagedUser = await localStorage.getItem('@RNAuth:user')
      const storagedToken = await localStorage.getItem('@RNAuth:token')

      if (storagedUser && storagedToken) {
        setUser(JSON.parse(storagedUser))
      }
    }

    loadStorageData()
  }, [])

  // function signIn(login:string, senha:string, captcha?: string) {
  function signIn(login: string, senha: string) {
    return new Promise<iReturn>(async resolve => {
      // const response : iLoginResponse = await auth.signIn(login, senha, captcha);
      const response: iLoginResponse = await auth.signIn(login, senha)
      if (response) {
        if (response.auth) {
          setUser(response.operador)
          await localStorage.setItem(
            '@RNAuth:user',
            JSON.stringify(response.operador)
          )
          await localStorage.setItem('@RNAuth:token', response.token)
          resolve({ signed: true, message: '' })
        } else {
          resolve({ signed: false, message: response.message })
        }
      } else {
        resolve({ signed: false, message: '' })
      }
      setLoading(false)
    })
  }

  async function signOut() {
    await localStorage.clear()
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{ signed: !!user, setLoading, loading, user, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
