import React, { useEffect, useRef, useState } from 'react'

import Button from '../../components/Button'
import Input from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import RadioButton from '../../components/RadioButton'
import { Select } from '../../components/Select'
import Sidebar from '../../components/Sidebar'
import { iRow, Table } from '../../components/Table'
import {
  iCidade,
  iCliente,
  iClienteEndereco,
  iClienteVeiculo,
  iSelect,
  iTabelaCodigo,
  iv1OperadorCliente
} from '../../interfaces/interfaces'
import api from '../../services/api'
import {
  maskCEP,
  maskCNPJ,
  maskCPF,
  maskPlaca,
  validaCPF
} from '../../services/utils'

import './styles.css'
import { useHistory, useParams } from 'react-router'

import Dialog from '../../components/Dialog'
import Checkbox from '../../components/Checkbox'

import { FaPlus } from 'react-icons/fa'

export default function ClienteEdit() {
  const [modoTela, setModoTela] = useState<'NEW' | 'EDIT'>('NEW')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dadosCliente, setDadosCliente] = useState<iv1OperadorCliente>({
    tctipo_cliente: 'pessoaFisica'
  } as iv1OperadorCliente)
  const [clienteVeiculosRow, setClienteVeiculosRow] = useState<Array<iRow>>([])
  const [clienteVeiculos, setClienteVeiculos] = useState<
    Array<iClienteVeiculo>
  >([])
  const [dadosEndereco, setDadosEndereco] = useState<iClienteEndereco>()
  const [tcunidade_federacao, setTcunidade_federacao] = useState<string>()
  const [loadingCidade, setLoadingCidade] = useState(false)
  const [loadingEstado, setLoadingEstado] = useState(true)
  const [cidades, setCidades] = useState<Array<iSelect>>([])
  const [estados, setEstados] = useState<Array<iSelect>>([])
  const [tipoPessoa, setTipoPessoa] = useState('pessoaFisica')
  const [disableEndereco, setDisableEndereco] = useState(true)
  const [placa, setPlaca] = useState<string>()
  const [marca, setMarca] = useState<string>()
  const [modelo, setModelo] = useState<string>()
  const [ano, setAno] = useState<number | null>()
  const [cor, setCor] = useState<string>()

  const navigation = useHistory()
  const logradouroRef = useRef<HTMLInputElement>()
  const numeroRef = useRef<HTMLInputElement>()
  const cpfcnpjref = useRef<HTMLInputElement>()

  interface iParams {
    idcliente: string
  }

  const params = useParams<iParams>()

  useEffect(() => {
    if (params.idcliente !== '-1') {
      setModoTela('EDIT')
      console.log('idcleitne inicio tela: ', params.idcliente)

      buscaDadosCliente(parseInt(params.idcliente))
    }

    buscaEstados()
  }, [])

  // useEffect(() => {
  //   setDadosCliente({ ...dadosCliente, ["nrcpf_cnpj"]: "" });
  // }, [tipoPessoa])

  function buscaDadosCliente(idcliente: number) {
    api
      .get(`cliente/${idcliente}`)
      .then(res => {
        console.log('buscaDadosCliente-get.cliente')
        console.log(res.data)

        const data: iv1OperadorCliente = res.data as iv1OperadorCliente
        const _clienteVeiculosRow: Array<iRow> = []
        const param = JSON.parse(data.parametro!)

        console.log('dados cliente parametro: ', data.parametro)
        console.log('dados cliente boleto: ', param)
        data.isrecebe_boleto_email = param.recebeBoletoEmail
        data.isrecebe_boleto_whatsapp = param.recebeBoletoWhatsapp
        data.isrecebe_lembrete_vencimento = param.recebeLembreteVencimento

        console.log('data popular', data)

        setTipoPessoa(data.tctipo_cliente || '')
        setTcunidade_federacao(
          data.cliente_endereco?.cidade.tcunidade_federacao
        )
        buscaCidades(data.cliente_endereco?.cidade.tcunidade_federacao || '')
        setDadosCliente(data)
        setDadosEndereco(data.cliente_endereco)
        // setClienteVeiculos(data.cliente_veiculos || []);

        console.log('dados cliente: ', data)

        data.cliente_veiculos?.map((value, index) => {
          if (value.idcliente_veiculo) {
            _clienteVeiculosRow.push(makeCarRow(value))
          }
        })
        setClienteVeiculosRow(_clienteVeiculosRow)
      })
      .catch(e => {
        console.log('catch busca clientes')
        console.log(e)
        alert('Ocorreu um erro ao buscar os dados do cliente, tente novamente')
      })
  }

  function buscaEstados() {
    api
      .get('/estados')
      .then(res => {
        const data: Array<iTabelaCodigo> = res.data
        const _estados: Array<iSelect> = data.map((value, index) => {
          return { label: value.txvalor, value: value.tcchave }
        })
        setEstados(_estados)
      })
      .catch(() => {
        alert('Ocorreu um erro ao buscar os estados, recarregue a pagina')
      })
      .finally(() => {
        setLoadingEstado(false)
      })
  }

  function buscaCidades(estado: string) {
    setLoadingCidade(true)
    api
      .get(`cidades/${estado}`)
      .then(res => {
        const data: Array<iCidade> = res.data
        const _cidades: Array<iSelect> = data.map((value, index) => {
          return { label: value.nmcidade, value: value.idcidade.toString() }
        })
        setCidades(_cidades)
      })
      .catch(() => {
        alert('Ocorreu um erro ao buscar as cidades, recarregue a pagina')
      })
      .finally(() => {
        setLoadingCidade(false)
      })
  }

  function buscaCEP(cep: string | number) {
    api
      .get(`busca/cep/${cep}`)
      .then(res => {
        const data = res.data
        if (!data.txlogradouro) {
          setDisableEndereco(false)
          logradouroRef.current?.focus()
        } else {
          setDisableEndereco(true)
          numeroRef.current?.focus()
        }

        const _dadosEndereco: iClienteEndereco = {
          nrCEP: data.nrcep,
          txlogradouro: data.txlogradouro,
          txbairro: data.txbairro,
          idcidade: data.idcidade
        } as iClienteEndereco
        setTcunidade_federacao(data.tcuf)
        buscaCidades(data.tcuf)
        setDadosEndereco(_dadosEndereco)
      })
      .catch(e => {
        const error = e.response?.data
        alert(
          error.message
            ? error.message
            : 'Ocorreu um erro ao buscar o CEP, tente novamente'
        )
      })
  }

  function handleSaveCliente() {
    const saveCliente = {
      ...dadosCliente,
      cliente_endereco: { ...dadosEndereco },
      cliente_veiculos: [...clienteVeiculos]
    }

    delete saveCliente.isrecebe_boleto_email
    delete saveCliente.isrecebe_boleto_whatsapp
    delete saveCliente.isrecebe_lembrete_vencimento

    console.log('gravar dados')
    console.log(dadosCliente)

    if (modoTela === 'EDIT') {
      api
        .patch('/cliente', saveCliente)
        .then(res => {
          const data = res.data
          if (!data.error) {
            setDialogOpen(false)
            alert(data.message)
            // window.location.reload();
          } else {
            alert(data.message)
          }
        })
        .catch(e => {
          alert('Ocorreu um erro ao atualizar o cliente')
        })
    } else {
      api
        .post('cliente', saveCliente)
        .then(res => {
          alert('Cliente salvo com sucesso!')
          navigation.goBack()
        })
        .catch(e => {
          alert('Ocorreu um erro ao salvar o cliente')
        })
    }
  }

  function trocaDadosCliente(a: any) {
    const obj: iv1OperadorCliente = { ...dadosCliente, [a.id]: a.value }

    console.log('troca dados ', a.id, a.value)

    // parametro: "{\"recebeBoletoEmail\": true, \"recebeBoletoWhatsapp\": true, \"recebeLembreteVencimento\" : true}"

    if (a.id === 'isrecebe_boleto_email') {
      obj['parametro'] = `{"recebeBoletoEmail":${dadosCliente.isrecebe_boleto_email ? false : true
        }, "recebeBoletoWhatsapp": ${dadosCliente.isrecebe_boleto_whatsapp
        },"recebeLembreteVencimento":${dadosCliente.isrecebe_lembrete_vencimento
        }  }`
      obj.isrecebe_boleto_email = dadosCliente.isrecebe_boleto_email
        ? false
        : true
    } else if (a.id === 'isrecebe_boleto_whatsapp') {
      obj['parametro'] = `{"recebeBoletoEmail":${dadosCliente.isrecebe_boleto_email
        }, "recebeBoletoWhatsapp": ${dadosCliente.isrecebe_boleto_whatsapp ? false : true
        },"recebeLembreteVencimento":${dadosCliente.isrecebe_lembrete_vencimento
        }  }`
      obj.isrecebe_boleto_whatsapp = dadosCliente.isrecebe_boleto_whatsapp
        ? false
        : true
    } else if (a.id === 'isrecebe_lembrete_vencimento') {
      obj['parametro'] = `{"recebeBoletoEmail":${dadosCliente.isrecebe_boleto_email
        }, "recebeBoletoWhatsapp": ${dadosCliente.isrecebe_boleto_whatsapp
        },"recebeLembreteVencimento":${dadosCliente.isrecebe_lembrete_vencimento ? false : true
        }  }`
      obj.isrecebe_lembrete_vencimento =
        dadosCliente.isrecebe_lembrete_vencimento ? false : true
    }

    console.log('objeto corrigido: ', obj)

    setDadosCliente(obj)
  }

  function trocaDadosEndereco(a: any) {
    const obj: iClienteEndereco = {
      ...dadosEndereco,
      [a.id]: a.value
    } as iClienteEndereco
    console.log(obj)

    setDadosEndereco(obj)
  }

  function removeCarRow(rowId: string) {
    console.log(clienteVeiculosRow)
  }

  function handleNewCar() {
    if (placa) {
      const rowId = (clienteVeiculosRow.length + 1).toString()
      const car: iClienteVeiculo = {
        nrplaca: placa,
        txmodelo: modelo,
        txmarca: marca,
        txcor: cor,
        nrano: ano
      } as iClienteVeiculo

      const _clienteVeiculos = [...clienteVeiculos, car]
      setClienteVeiculos(_clienteVeiculos)

      const row = makeCarRow(car)

      const _clienteVeiculosRow = [...clienteVeiculosRow, row]
      setClienteVeiculosRow(_clienteVeiculosRow)
      setPlaca('')
      setModelo('')
      setMarca('')
      setAno(null)
      setCor('')
    }
  }

  function makeCarRow(car: iClienteVeiculo) {
    const rowId = (clienteVeiculosRow.length + 1).toString()
    const row: iRow = {
      rowId: rowId,
      values: [
        maskPlaca(car.nrplaca),
        car.txmarca,
        car.txmodelo,
        car.nrano,
        car.txcor,
        car.tcstatus_veiculo
      ]
    }

    return row
  }

  return (
    <div id="page-cliente">
      <PageHeader></PageHeader>
      <Sidebar></Sidebar>
      <div className="container">
        <div className="content">
          <h2>Dados Cadastrais</h2>
          <div className="bloco">
            <div>
              <RadioButton
                label="Fisica"
                name="tctipo_cliente"
                value="pessoaFisica"
                checked={tipoPessoa === 'pessoaFisica' ? true : false}
                onChange={e => {
                  setTipoPessoa(e.target.value)
                  trocaDadosCliente(e.target)
                }}
              />
              <RadioButton
                label="Juridica"
                value="pessoaJuridica"
                name="tctipo_cliente"
                checked={tipoPessoa === 'pessoaJuridica' ? true : false}
                onChange={e => {
                  setTipoPessoa(e.target.value)
                  trocaDadosCliente(e.target)
                }}
              />
            </div>
            <Input
              type="text"
              label={tipoPessoa === 'pessoaFisica' ? 'Nome' : 'Razão Social'}
              placeholder={
                tipoPessoa === 'pessoaFisica' ? 'Nome' : 'Razão Social'
              }
              name="txnome_cliente"
              value={dadosCliente?.txnome_cliente}
              onChange={e => {
                trocaDadosCliente(e.target)
              }}
            />
            {tipoPessoa === 'pessoaFisica' ? (
              <Input
                type="text"
                label="Sobrenome"
                placeholder="Sobrenome"
                name="txsobrenome_cliente"
                value={dadosCliente?.txsobrenome_cliente}
                onChange={e => {
                  trocaDadosCliente(e.target)
                }}
              />
            ) : (
              <Input
                type="text"
                label="Nome Fantasia"
                placeholder="Nome Fantasia"
                name="txnome_fantasia"
                value={dadosCliente?.txnome_fantasia}
                onChange={e => {
                  trocaDadosCliente(e.target)
                }}
              />
            )}
            <Input
              type="text"
              label={tipoPessoa === 'pessoaFisica' ? 'CPF' : 'CNPJ'}
              placeholder={tipoPessoa === 'pessoaFisica' ? 'CPF' : 'CNPJ'}
              name="nrcpf_cnpj"
              ref={cpfcnpjref}
              maxLength={tipoPessoa === 'pessoaFisica' ? 14 : 18}
              value={
                tipoPessoa === 'pessoaFisica'
                  ? maskCPF(dadosCliente?.nrcpf_cnpj || '')
                  : maskCNPJ(dadosCliente?.nrcpf_cnpj || '')
              }
              onBlur={e => {
                if (
                  e.target.value &&
                  tipoPessoa === 'pessoaFisica' &&
                  !validaCPF(e.target.value)
                ) {
                  cpfcnpjref.current?.setCustomValidity('CPF Inválido')
                } else {
                  cpfcnpjref.current?.setCustomValidity('')
                }
              }}
              onChange={e => {
                e.target.value = e.target.value
                  .replaceAll('.', '')
                  .replaceAll('-', '')
                  .replaceAll('/', '')
                trocaDadosCliente(e.target)
              }}
            />
            <Input
              label="E-mail"
              name="txemail"
              placeholder="E-mail"
              value={dadosCliente?.txemail}
              onChange={e => {
                trocaDadosCliente(e.target)
              }}
            />
            <div className="row">
              <Input
                type="text"
                label="DDD Celular"
                placeholder="DDD"
                name="nrddd_celular"
                value={dadosCliente?.nrddd_celular}
                onChange={e => {
                  trocaDadosCliente(e.target)
                }}
              />
              <Input
                type="text"
                label="Celular"
                placeholder="Celular"
                name="nrcelular"
                value={dadosCliente?.nrcelular}
                onChange={e => {
                  trocaDadosCliente(e.target)
                }}
              />
              <Input
                type="text"
                label="DDD Telefone"
                placeholder="DDD"
                name="nrddd_telefone"
                value={dadosCliente?.nrddd_telefone}
                onChange={e => {
                  trocaDadosCliente(e.target)
                }}
              />
              <Input
                type="text"
                label="Telefone"
                placeholder="Telefone"
                name="nrtelefone"
                value={dadosCliente?.nrtelefone}
                onChange={e => {
                  trocaDadosCliente(e.target)
                }}
              />
            </div>
            <div>
              <div className="subtitle">
                <h3>Endereço</h3>
                <hr />
              </div>
              <div className="row">
                <Input
                  label=""
                  placeholder="CEP"
                  name="nrCEP"
                  maxLength={9}
                  value={maskCEP(dadosEndereco?.nrCEP || '')}
                  onChange={e => {
                    const value = e.target.value.replace('-', '')
                    if (value.length == 8) {
                      buscaCEP(value)
                    }
                    trocaDadosEndereco(e.target)
                  }}
                />
                <Input
                  label=""
                  placeholder="Logradouro"
                  customClass="logradouro"
                  disabled={disableEndereco}
                  ref={logradouroRef}
                  value={dadosEndereco?.txlogradouro}
                  name="txlogradouro"
                  onChange={e => {
                    trocaDadosEndereco(e.target)
                  }}
                />
                <Input
                  label=""
                  placeholder="Numero"
                  ref={numeroRef}
                  name="nrlogradouro"
                  value={dadosEndereco?.nrlogradouro}
                  onChange={e => {
                    trocaDadosEndereco(e.target)
                  }}
                />
              </div>
              <div className="row">
                <Input
                  label=""
                  placeholder="Bairro"
                  disabled={disableEndereco}
                  value={dadosEndereco?.txbairro}
                  name="txbairro"
                  onChange={e => {
                    trocaDadosEndereco(e.target)
                  }}
                />
                <Select
                  name="tcunidade_federacao"
                  label=""
                  placeholder="Selecione o estado"
                  disabled={true}
                  loading={loadingEstado}
                  value={tcunidade_federacao || ''}
                  onChange={e => {
                    buscaCidades(e.target.value)
                    setTcunidade_federacao(e.target.value)
                  }}
                  options={estados}
                />
                <Select
                  name="idcidade"
                  label=""
                  placeholder="Selecione a cidade"
                  loading={loadingCidade}
                  disabled={true}
                  value={dadosEndereco?.idcidade || ''}
                  onChange={e => {
                    trocaDadosEndereco(e.target)
                  }}
                  options={cidades}
                />
                <Input
                  label=""
                  placeholder="Complemento"
                  name="txcomplemento"
                  value={dadosEndereco?.txcomplemento}
                  onChange={e => {
                    trocaDadosEndereco(e.target)
                  }}
                />
              </div>
            </div>
            <div>
              <div className="subtitle">
                <h3>Faturamento</h3>
                <hr />
              </div>
              <div className="row">
                <Input
                  label="Dia Vencto"
                  placeholder="vencimento"
                  name="nrdia_vencimento"
                  maxLength={2}
                  value={dadosCliente?.nrdia_vencimento}
                  onChange={e => {
                    trocaDadosCliente(e.target)
                  }}
                />
                <div className="blocoDireita">
                  <Checkbox
                    label="Recebe Boleto Whatsapp"
                    name="isrecebe_boleto_whatsapp"
                    checked={dadosCliente?.isrecebe_boleto_whatsapp}
                    onChange={e => {
                      trocaDadosCliente(e.target)
                    }}
                  ></Checkbox>
                  <Checkbox
                    label="Recebe Boleto Email"
                    name="isrecebe_boleto_email"
                    checked={dadosCliente?.isrecebe_boleto_email}
                    onChange={e => {
                      trocaDadosCliente(e.target)
                    }}
                  ></Checkbox>
                  <Checkbox
                    label="Lembrete Vencimento"
                    name="isrecebe_lembrete_vencimento"
                    checked={dadosCliente?.isrecebe_lembrete_vencimento}
                    onChange={e => {
                      trocaDadosCliente(e.target)
                    }}
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>

          {/* ***** dados dos veículos ********** */}
          <h2>Veículos</h2>
          <div className="bloco">
            <div className="row addCarFields">
              <Input
                label="Placa"
                placeholder="Placa"
                maxLength={8}
                name="placa"
                autoCapitalize="characters"
                value={maskPlaca(placa || '')}
                onChange={e => {
                  e.target.value = e.target.value.replaceAll('-', '')
                  setPlaca(e.target.value)
                }}
              />
              <Input
                label="Modelo"
                placeholder="Modelo"
                value={modelo}
                name="modelo"
                onChange={e => {
                  setModelo(e.target.value)
                }}
              />
              <Input
                label="Marca"
                placeholder="Marca"
                value={marca}
                name="marca"
                onChange={e => {
                  setMarca(e.target.value)
                }}
              />
              <Input
                label="Ano"
                placeholder="Ano"
                value={ano || ''}
                name="ano"
                maxLength={4}
                onChange={e => {
                  setAno(parseInt(e.target.value))
                }}
              />
              <Input
                label="Cor"
                placeholder="Cor"
                value={cor}
                name="cor"
                onChange={e => {
                  setCor(e.target.value)
                }}
              />
              <div className="controls">
                <Button customClass="add-veiculo" onClick={handleNewCar}>
                  <FaPlus />
                </Button>
              </div>
            </div>
            <Table
              customClass="table-cliente-veiculo"
              labels={['Placa', 'Marca', 'Modelo', 'Ano', 'Cor', 'Situação']}
              data={clienteVeiculosRow}
              name="table-veiculos-cliente"
              loading={
                modoTela == 'EDIT' && clienteVeiculosRow.length == 0
                  ? true
                  : false
              }
              qtloading={3}
            />
          </div>
          <div className="bloco">
            <div className="row button-controls">
              <Button
                onClick={
                  modoTela === 'EDIT'
                    ? () => {
                      setDialogOpen(true)
                    }
                    : handleSaveCliente
                }
              >
                Salvar
              </Button>
              <Button
                onClick={() => {
                  navigation.goBack()
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        title="Atenção!"
        text="Tem certeza que deseja salvar ?"
        isOpen={dialogOpen}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        buttons={[
          {
            text: 'Não',
            type: 'cancel'
          },
          {
            text: 'Sim',
            type: 'button',
            onClick: handleSaveCliente
          }
        ]}
        onRequestClose={() => {
          setDialogOpen(false)
        }}
      ></Dialog>
    </div>
  )
}
