import React, { useEffect, useState } from 'react'
import { FaEdit, FaPlus } from 'react-icons/fa'
import Button from '../../components/Button'
import PageHeader from '../../components/PageHeader'
import Sidebar from '../../components/Sidebar'
import { iRow, Table } from '../../components/Table'
import { iChip } from '../../interfaces/interfaces'
import api from '../../services/api'
import { maskFone } from '../../services/utils'

import './styles.css'

function Chips() {
  const [tableLoading, setTableLoading] = useState(false)
  const [chips, setChips] = useState<Array<iRow>>([])

  useEffect(() => {
    buscaChips()
  }, [])

  function buscaChips() {
    api
      .get('/chips')
      .then(res => {
        const data = res.data as Array<iChip>
        const _chips: Array<iRow> = data.map((value, index) => {
          return {
            rowId: value.idchip.toString(),
            values: [
              maskFone(value.nrfone),
              value.cdchip_operadora,
              value.txoperadora,
              value.fornecedor?.txfornecedor,
              <div
                key={value.idchip.toString()}
                className={`statusChip ${value.tcsituacao_chip}`}
              >
                <span>{value.tcsituacao_chip}</span>
              </div>,
              <div key={value.idchip.toString()}>
                <Button to={`chips/edit/${value.idchip}`}>
                  <FaEdit />
                </Button>
              </div>
            ]
          }
        })
        setChips(_chips)
      })
      .catch(e => {
        console.log(e)
        alert('Ocorreu um erro ao bucar os chips')
      })
  }

  return (
    <div id="page-chips">
      <PageHeader />
      <Sidebar />
      <div className="container">
        <div className="content">
          <div className="bloco">
            <div className="buttons-container">
              <Button to="/chips/edit/-1">
                <FaPlus />
                Novo
              </Button>
              {/* <a href="/operador/edit/-1"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>Novo</a> */}
            </div>
          </div>
          <div className="bloco">
            {/* <Input
              type="text"
              label="Filtrar"
              placeholder="digite o nome do operador"
              name="filtro"
              value={filtroNome}
              onChange={(e) => {
                setfiltroNome(e.target.value);
              }}
            /> */}
          </div>
          <div className="bloco">
            <Table
              labels={[
                'Numero',
                'ICCID',
                'Operadora',
                'Fornecedor',
                'Situação',
                'Ações'
              ]}
              data={chips}
              customClass="table-chips"
              name="table-chips"
              qtloading={4}
              loading={tableLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chips
