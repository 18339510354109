import React, { useContext } from 'react'
import AuthContext from '../../contexts/auth'

import './styles.css'

const Loading = () => {
  const { setLoading, loading } = useContext(AuthContext)

  return (
    <div style={{ display: loading ? 'flex' : 'none' }} id="loading">
      <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div>
    </div>
  )
}

export default Loading
