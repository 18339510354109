import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Input from '../../components/Input'
import api from '../../services/api'

import './styles.css'

interface iParams {
  token: string
}

function PasswordReset() {
  const { token } = useParams<iParams>()
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [verified, setVerified] = useState(true)
  const history = useHistory()

  useEffect(() => {
    api
      .post('/password-reset/token/verify', { token })
      .then(res => {
        if (!res.data.verified) {
          alert('Token Inválido!')
          history.push('/')
        }
      })
      .catch(err => {
        alert('Não foi possivel verificar seu token!')
        history.push('/')
      })
  }, [])

  function handleResetPassword() {
    api
      .post('/password-reset/reset', { token, password, passwordConfirm })
      .then(res => {
        alert(res.data.message)
        if (!res.data.error) {
          history.push('/')
        }
      })
      .catch(err => {
        alert('Não foi possivel resetar sua senha, tente novamente!')
      })
  }

  return (
    <div id="page-password-reset">
      <div id="page-password-reset-content" className="container">
        <div className="password-reset-box">
          <div className="password-reset-box-header">
            <p>Redefinir senha</p>
            <small>Digite a senha e a confirmação para alterar a senha!</small>
          </div>
          <div className="password-reset-box-body">
            <Input
              type="password"
              placeholder="Senha"
              label=""
              name="password"
              value={password}
              onChange={e => {
                setPassword(e.target.value)
              }}
            />
            <Input
              type="password"
              placeholder="Confirmação de senha"
              label=""
              name="password_confirm"
              value={passwordConfirm}
              onChange={e => {
                setPasswordConfirm(e.target.value)
              }}
            />
          </div>
          <div className="password-reset-box-footer">
            <button type="button" onClick={handleResetPassword}>
              Alterar a senha
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordReset
