import React, { useState, useContext } from 'react'
import Input from '../../components/Input'
import logoImg from '../../assets/images/logo.svg'
import AuthContext from '../../contexts/auth'
// import ReCAPTCHA from 'react-google-recaptcha'

import './styles.css'
import '../../assets/styles/loader.css'

const Login: React.FC = () => {
  const [login, setLogin] = useState('')
  const [senha, setSenha] = useState('')
  // const [captcha, setCaptcha] = useState<string | undefined>()
  const { signIn, setLoading } = useContext(AuthContext)

  async function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!login || !senha) {
      alert('Preencha o usuario e a senha!')
      return
    }

    // if(!captcha) {
    //     alert("Por favor preencha o ReCaptcha!");
    //     return;
    // }

    setLoading(true)
    // var {signed, message} = await signIn(login, senha, captcha);
    const { signed, message } = await signIn(login, senha)
    if (!signed) {
      if (message) {
        alert(message)
      } else {
        alert('Ocorreu um erro ao entrar, por favor tente novamente')
      }
    }
  }

  return (
    <div id="page-login">
      <form onSubmit={handleLogin}>
        <div className="content">
          <div className="login-box">
            <div className="login-box-header">
              <img src={logoImg} width={200} height={200} alt="Radar System" />
            </div>
            <div className="login-box-body">
              <Input
                placeholder="Usuario"
                label=""
                name="username"
                value={login}
                onChange={e => {
                  setLogin(e.target.value)
                }}
              />
              <Input
                type="password"
                placeholder="Senha"
                label=""
                name="password"
                value={senha}
                onChange={e => {
                  setSenha(e.target.value)
                }}
              />
            </div>
            <div className="login-box-footer">
              <div className="controls">
                {/* <ReCAPTCHA
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ""}
                                onChange={(e)=> {setCaptcha(e || undefined)}}
                                onErrored={()=> {setCaptcha(undefined)}}
                                onExpired={()=>{setCaptcha(undefined)}}
                            /> */}
                {/* <Checkbox name="remember-me" label="Lembrar-me" /> */}
                {/* <Link to="/forgot-password">Esqueci minha senha</Link> */}
              </div>
              <button type="submit">Entrar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
