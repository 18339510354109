import React from 'react'
import * as Icons from 'react-icons/fa'

import './styles.css'

interface iInfoBox {
  icon: iInfoBoxIcon
  text: iInfoBoxText
}

interface iInfoBoxIcon {
  icon: string
  color: string | 'white'
  backgroundColor: string | 'var(--color-primary)'
}

interface iInfoBoxText {
  title: string
  subtitle: string
}

const Icon = ({ name }) => {
  const IconComponent = Icons[name]
  if (!IconComponent) {
    return <Icons.FaBeer />
  }
  return <IconComponent />
}

const InfoBox: React.FC<iInfoBox> = ({ icon, text }) => {
  return (
    <div className="info-box">
      <div className="info-box-body">
        <div className="info-box-content">
          <div
            className="info-box-icon"
            style={{ background: icon.backgroundColor, color: icon.color }}
          >
            <Icon name={icon.icon} />
          </div>
          <div className="info-box-text">
            <span>{text.title}</span>
            <h4>{text.subtitle}</h4>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default InfoBox
