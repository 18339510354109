import React, { useState } from 'react'
import PageHeader from '../../components/PageHeader'
import Sidebar from '../../components/Sidebar'

import './styles.css'

function FinanceiroDetalhe() {
  const [idfatura, setIdfatura] = useState()
  return (
    <div id="page-financeiro-detalhe">
      <PageHeader />
      <Sidebar />
      <p>{idfatura}</p>
    </div>
  )
}

export default FinanceiroDetalhe
