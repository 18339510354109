import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Input from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import { Select } from '../../components/Select'
import Sidebar from '../../components/Sidebar'
import { iChip, iFornecedor, iSelect } from '../../interfaces/interfaces'
import api from '../../services/api'

export default function ChipEdit() {
  const [modoTela, setModoTela] = useState('NEW')
  const [dadosChip, setDadosChip] = useState<iChip>()
  const [fornecedores, setFornecedores] = useState<Array<iSelect>>([])
  const [loadingFornecedor, setLoadingFornecedor] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const navigation = useHistory()

  interface iParams {
    idchip: string
  }

  const params = useParams<iParams>()

  useEffect(() => {
    if (params.idchip !== '-1') {
      setModoTela('EDIT')
      buscaDadosChip(parseInt(params.idchip))
    }
    buscaFornecedores()
  }, [])

  function buscaDadosChip(idchip: number) {
    api
      .get(`chip/${idchip}`)
      .then(res => {
        let data = res.data as iChip
        const dtcompra = new Date(data.dtcompra)
        data = {
          ...data,
          dtcompra: `${dtcompra.toISOString().substring(0, 10)}`
        }
        setDadosChip(data)
      })
      .catch(e => {
        console.log(e)
        alert('Ocorreu um erro ao buscar os dados do chip')
      })
  }

  function buscaFornecedores() {
    setLoadingFornecedor(true)
    api
      .get('/fornecedor')
      .then(res => {
        const data: Array<iFornecedor> = res.data as Array<iFornecedor>
        const _fornecedor: Array<iSelect> = data.map((value, index) => {
          return {
            value: value.idfornecedor.toString(),
            label: value.txfornecedor
          }
        })
        setFornecedores(_fornecedor)
      })
      .catch(e => {
        console.log(e)
        alert('Ocorreu um erro ao buscar os fornecedores, tente novamente')
      })
      .finally(() => {
        setLoadingFornecedor(false)
      })
  }

  function handleSaveChip() {
    console.log('handleSaveChip')
  }

  function trocaDadosChip(a: any) {
    const obj: iChip = { ...dadosChip, [a.id]: a.value } as iChip
    setDadosChip(obj)
  }

  return (
    <div id="page-chip-edit">
      <PageHeader />
      <Sidebar />
      <div className="container">
        <div className="content">
          <div className="bloco">
            <Select
              label="Fornecedor"
              name="idfornecedor"
              placeholder="Selecione o fornecedor"
              value={dadosChip?.idfornecedor}
              loading={loadingFornecedor}
              options={fornecedores}
            />
            <Input
              label="Telefone"
              name="nrfone"
              placeholder="Telefone"
              value={dadosChip?.nrfone}
              onChange={e => {
                trocaDadosChip(e)
              }}
            />
            <Input
              label="Operadora"
              name="txoperadora"
              placeholder="Operadora"
              value={dadosChip?.txoperadora}
              onChange={e => {
                trocaDadosChip(e)
              }}
            />
            <Input
              label="Valor Pago"
              type="number"
              step="0.5"
              name="vlpago"
              placeholder="Valor Pago"
              value={dadosChip?.vlpago}
              onChange={e => {
                trocaDadosChip(e)
              }}
            />
            <Input
              label="Valor Ativação"
              type="number"
              name="vlativacao"
              placeholder="Valor Ativação"
              value={dadosChip?.vlativacao}
              onChange={e => {
                trocaDadosChip(e)
              }}
            />
            <Input
              label="Valor Mensalidade"
              type="number"
              name="vlmensalidade"
              placeholder="Valor Mensalidade"
              value={dadosChip?.vlmensalidade}
              onChange={e => {
                trocaDadosChip(e)
              }}
            />
            <Input
              label="ICCID"
              name="cdchip_operadora"
              placeholder="ICCID"
              value={dadosChip?.cdchip_operadora}
              onChange={e => {
                trocaDadosChip(e)
              }}
            />
            <Input
              type="date"
              label="Data da compra"
              name="dtcompra"
              value={dadosChip?.dtcompra}
              onChange={e => {
                trocaDadosChip(e)
              }}
            />
          </div>

          <div className="bloco">
            <div className="row button-controls">
              <Button
                onClick={
                  modoTela === 'EDIT'
                    ? () => {
                        setDialogOpen(true)
                      }
                    : handleSaveChip
                }
              >
                Salvar
              </Button>
              <Button
                onClick={() => {
                  navigation.goBack()
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
