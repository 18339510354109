import React, { InputHTMLAttributes } from 'react'

import './styles.css'

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
}

const RadioButton: React.FC<RadioProps> = ({ label, name, ...rest }) => {
  return (
    <label className="radio-button" htmlFor={rest.id}>
      {' '}
      {label}
      <input id={name} type="radio" name={name} {...rest} />
      <span className="checkmark"></span>
    </label>
  )
}

export default RadioButton
