import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Input from '../../components/Input'

import PageHeader from '../../components/PageHeader'
import Sidebar from '../../components/Sidebar'
import api from '../../services/api'
import Skeleton from 'react-loading-skeleton'

import Modal from 'react-modal'
import AuthContext from '../../contexts/auth'
import './styles.css'
import { Select } from '../../components/Select'
import {
  iParams,
  iv1OperadorCliente,
  iOperador,
  iClienteVeiculo,
  iCliente
} from '../../interfaces/interfaces'
import { iRow, Table } from '../../components/Table'
import Inputfile from '../../components/InputFile'
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleLeft,
  FaSave
} from 'react-icons/fa'

let aDadosClientes: Array<iv1OperadorCliente> = []

function OperadorEdit() {
  const params = { filtro: {} }

  const { user, signOut } = useContext(AuthContext)

  const { aidoperador } = useParams<iParams>()

  const [idoperador, setidoperador] = useState(aidoperador)

  const [operador, setoperador] = useState<iOperador>()

  const [cdoperador, setcdoperador] = useState<string>('')
  const [tpoperador, settpoperador] = useState<number>()
  const [txemail, settxemail] = useState<string>('')

  const [cdsenha, setcdsenha] = useState<string>('')

  const [dadosCliente, setdadosClientes] = useState<iv1OperadorCliente>()

  const [nomecliente, setnomecliente] = useState<string | undefined>('')

  const [nratualiza, setnratualiza] = useState(0)
  const [clientes, setclientes] = useState()
  const [clientesOperador, setclientesOperador] = useState<
    Array<{ value: string; label: string }>
  >([])

  const tipoOperador = [
    { value: '0', label: 'Admin' },
    { value: '1', label: 'Cliente' }
  ]

  const [loading, setLoading] = useState(true)
  const [modalIsOpen, setIsOpen] = useState(false)

  const [clienteVeiculos, setClientesVeiculos] = useState<Array<iRow>>()

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  async function gravarDados() {
    const d2 = new Date()

    const regoperador = {
      idoperador: operador?.idoperador,
      cdoperador: cdoperador,
      tpoperador: tpoperador,
      novasenha: cdsenha,
      dtcadastro: d2.toDateString(),
      txemail: txemail
    }

    const r = { operador: regoperador }

    if (operador?.idoperador !== undefined) {
      api
        .put('/operador/', r)
        .then(res => {
          closeModal()
          alert('Dados gravados com sucesso!')
        })
        .catch(e => {
          const error = e.response
          console.log(error)
          closeModal()
          alert(
            error.data.message +
            '\n' +
            error.data.errointerno +
            '\n' +
            error.data.erroBD
          )
        })
    } else {
      api
        .post('/operador/create', { dados: regoperador })
        .then(res => {
          closeModal()
          if (res.data.status === -1) {
            alert(res.data.mensagem + '\n' + res.data.erro.errointerno)
          } else {
            alert('Operador criado com suscesso')
            params.filtro = { cdoperador: cdoperador }

            api
              .post('/operador/', params)
              .then(res => {
                if (res.data.mensagem === 'Erro na busca: operador') {
                  alert(res.data.mensagem)
                } else {
                  settpoperador(3)

                  setoperador(res.data[0])
                  setcdoperador(res.data[0].cdoperador)
                  settpoperador(res.data[0].tpoperador)
                  setidoperador(res.data[0].idoperador)
                  settxemail(res.data[0].txemail)
                  setLoading(false)
                }
              })
              .catch(e => {
                setLoading(false)
                const error = e.response
                alert(error)
                //alert(error.data.mensagem + " / " + error.data.erroBD) ;
              })
          }
        })
        .catch(e => {
          const error = e.response
          console.log(error)
          closeModal()
          alert(
            error.data.message +
            '\n' +
            error.data.errointerno +
            '\n' +
            error.data.erroBD
          )
        })
    }
  }

  useEffect(() => {
    loadFunc()
  }, [])

  function gravaCliente() {
    const rCliente = {
      idcliente: dadosCliente?.idcliente,
      txnome_cliente: dadosCliente?.txnome_cliente,
      txsobrenome_cliente: dadosCliente?.txsobrenome_cliente,
      tctipo_cliente: dadosCliente?.tctipo_cliente,
      nrcpf_cnpj: dadosCliente?.nrcpf_cnpj,
      nrddd_celular: dadosCliente?.nrddd_celular,
      nrcelular: dadosCliente?.nrcelular,
      nrddd_telefone: dadosCliente?.nrddd_telefone,
      nrtelefone: dadosCliente?.nrtelefone
    }

    // religar o salvar os dados
    api.put('/cliente', { cliente: rCliente }).then(r => {
      null
    })
  }

  // Atualiza na tela os dados do cliente e do veiculo do cliente
  async function buscaDadosCliente(pidcliente: number) {
    // se trocou de cliente, salva os dados do cliente anterior
    gravaCliente()

    if (pidcliente === -99) {
      alert('criar cliente')
      let rdadosClientes = { ...dadosCliente }
      rdadosClientes = {
        idoperador_cliente: 0,
        idoperador: user?.idoperador || 0,
        idcliente: -99,
        txnome_cliente: '',
        txsobrenome_cliente: '',
        tctipo_cliente: '',
        nrcelular: '',
        nrcpf_cnpj: '',
        nrddd_celular: '',
        nrddd_telefone: '',
        nrtelefone: '',
        nrdia_vencimento: '',
        isrecebe_boleto_email: true,
        isrecebe_boleto_whatsapp: true,
        isrecebe_lembrete_vencimento: true
      }
      setdadosClientes(rdadosClientes)
    } else {
      // atualiza o array dos dados dos clientes que sao mostrados na tela
      const index = aDadosClientes.findIndex(
        dados => dados.idcliente === dadosCliente?.idcliente
      )
      if (index >= 0) {
        aDadosClientes[index].txnome_cliente =
          dadosCliente?.txnome_cliente || ''
        aDadosClientes[index].nrcelular = dadosCliente?.nrcelular
        aDadosClientes[index].nrcpf_cnpj = dadosCliente?.nrcpf_cnpj
        aDadosClientes[index].nrddd_celular = dadosCliente?.nrddd_celular
        aDadosClientes[index].nrddd_telefone = dadosCliente?.nrddd_telefone
        aDadosClientes[index].nrtelefone = dadosCliente?.nrtelefone
      }

      // atualiza a tela com os dados do cliente escolhido
      const result = aDadosClientes.find(
        dados => dados.idcliente === pidcliente
      )
      setdadosClientes(result)

      //busca no banco de dados os dados do veiculo do cliente ativo
      const paramCV = { filtro: { idcliente: pidcliente } }
      // busca veículos do cliente escolhido
      await api.post('/clienteVeiculo/list', paramCV).then(res => {
        console.log(res.data)

        const data: Array<iRow> = []
        res.data.map((value: any, index: number) => {
          data.push({
            rowId: value.idcliente_veiculo,
            values: [
              value.nrplaca,
              value.txmarca,
              value.txmodelo,
              value.nrano,
              value.txcor,
              value.txapelido
            ]
          })
        })
        setClientesVeiculos(data)
      })
    }
  }

  //atualiza a tela com os dados do cliente
  function trocaDadosClientes(a: any) {
    console.log('troca dados para ' + a)
    const obj: iv1OperadorCliente = { ...dadosCliente, [a.id]: a.value }
    setdadosClientes(obj)
  }

  async function loadFunc() {
    if (idoperador === '-1') {
      setLoading(false)
    } else {
      //carrega combobox dos clientes do operador
      let rClienteOperador = [...clientesOperador]
      rClienteOperador = []
      params.filtro = { idoperador: idoperador }
      await api.post('/operadorCliente/listv1', params).then(res => {
        res.data.map(function (item: any) {
          rClienteOperador.push({
            value: item.idcliente,
            label: item.txnome_cliente
          })
        })
        aDadosClientes = res.data
      })

      if (user?.tctipo_operador == 'admin') {
        rClienteOperador.push({
          value: '-99',
          label: '<<<<< Incluir Cliente >>>>'
        })
      }

      //inicializa os dados para compor o combobox
      setclientesOperador(rClienteOperador)
      //atualiza a tela com os dados do cliente inicial
      console.log(aDadosClientes[0].idcliente)
      setdadosClientes(aDadosClientes[0])

      await api
        .post('/operador/', params)
        .then(res => {
          if (res.data.mensagem === 'Erro na busca: operador') {
            alert(res.data.mensagem)
            return
          }
          setoperador(res.data[0])
          setcdoperador(res.data[0].cdoperador)
          settpoperador(res.data[0].tpoperador)
          settxemail(res.data[0].txemail)

          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
          const error = e.response
          alert(error.data.mensagem + ' / ' + error.data.erroBD)
        })

      if (operador?.idoperador === undefined) {
        params.filtro = { idoperador_secretaria: idoperador }
      } else {
        params.filtro = { idoperador_secretaria: operador.idoperador }
      }
    }
  }

  return (
    <div id="page-operador">
      <PageHeader></PageHeader>
      <Sidebar></Sidebar>
      <div className="container">
        <div className="content">
          <h1 className="titulo-grupo">Dados Operador </h1>

          {loading ? (
            <React.Fragment>
              <Skeleton height={28} width={50} />
              <Skeleton height={28} width={80} />
              <Skeleton height={28} width={50} />
              <Skeleton height={28} width={40} />
              <Skeleton height={28} width={100} />
            </React.Fragment>
          ) : null}

          <div key={operador?.idoperador}>
            <div className="bloco">
              <Inputfile label="Arquivo" name="fileinput" />
              <Input
                type="text"
                label="Codigo"
                placeholder="código do operador..."
                name="cdoperador"
                value={cdoperador}
                onChange={e => {
                  setcdoperador(e.target.value)
                }}
              />

              <Input
                type="text"
                label="Email"
                placeholder="email do operador..."
                name="txemail"
                value={txemail}
                onChange={e => {
                  settxemail(e.target.value)
                }}
              />
              {user?.tctipo_operador == 'admin' ? (
                <Select
                  name="tpoperador"
                  label="Tipo"
                  placeholder="Selecione um tipo"
                  value={tpoperador}
                  options={tipoOperador}
                  onChange={e => {
                    settpoperador(parseInt(e.target.value))
                  }}
                />
              ) : null}
              <Input
                type="text"
                label="Senha"
                placeholder="digite nova senha..."
                name="cdsenha"
                value={cdsenha}
                onChange={e => {
                  setcdsenha(e.target.value)
                }}
              />
              <div className="bloco">
                <div className="buttons-container">
                  <a onClick={openModal}>
                    <FaSave />
                    Gravar
                  </a>
                  <Link to="/operador">
                    <FaArrowAltCircleLeft /> Voltar
                  </Link>
                </div>
              </div>
            </div>

            {/* ***** dados do cliente ********** */}

            <h1>Dados Cadastrais</h1>
            <div className="bloco">
              <Select
                name="cliente"
                label="Cliente"
                placeholder="Selecione um cliente"
                value={dadosCliente?.idcliente}
                options={clientesOperador}
                onChange={e => {
                  buscaDadosCliente(parseInt(e.target.value))
                }}
              />

              <Input
                type="text"
                label="Nome"
                placeholder="nome..."
                name="txnome_cliente"
                value={dadosCliente?.txnome_cliente}
                onChange={e => {
                  trocaDadosClientes(e.target)
                }}
              />
              <Input
                type="text"
                label="CPF / CNPJ"
                placeholder="CPF/CNPJ..."
                name="nrcpf_cnpj"
                value={dadosCliente?.nrcpf_cnpj}
                onChange={e => {
                  trocaDadosClientes(e.target)
                }}
              />
              <Input
                type="text"
                label="nrddd_celular"
                placeholder="DDD..."
                name="nrddd_celular"
                value={dadosCliente?.nrddd_celular}
                onChange={e => {
                  trocaDadosClientes(e.target)
                }}
              />
              <Input
                type="text"
                label="nrcelular"
                placeholder="celular..."
                name="nrcelular"
                value={dadosCliente?.nrcelular}
                onChange={e => {
                  trocaDadosClientes(e.target)
                }}
              />
              <Input
                type="text"
                label="nrddd_telefone"
                placeholder="DDD..."
                name="nrddd_telefone"
                value={dadosCliente?.nrddd_telefone}
                onChange={e => {
                  trocaDadosClientes(e.target)
                }}
              />
              <Input
                type="text"
                label="nrtelefone"
                placeholder="telefone..."
                name="nrtelefone"
                value={dadosCliente?.nrtelefone}
                onChange={e => {
                  trocaDadosClientes(e.target)
                }}
              />
            </div>

            {/* ***** dados dos veículos ********** */}
            <h1>Veículos</h1>
            <div className="bloco">
              <Table
                labels={['Placa', 'Marca', 'Modelo', 'Ano', 'Cor', 'Apelido']}
                data={clienteVeiculos || []}
                name="table-veiculos-cliente"
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        id="modalGravar"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="userDialog"
      >
        <div className="titulo">Confirma a alteração dos dados ?</div>
        <div className="controls">
          <a onClick={closeModal} className="btnCancelar">
            Cancelar
          </a>
          <a onClick={gravarDados}>Gravar</a>
        </div>
      </Modal>
    </div>
  )
}

export default OperadorEdit
