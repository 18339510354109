import React, { useEffect, useState } from 'react'
import { Select } from '../../components/Select'
import PageHeader from '../../components/PageHeader'
import Sidebar from '../../components/Sidebar'
import api from '../../services/api'
import { iSelect, iCliente, iTituloReceber } from '../../interfaces/interfaces'

import { iRow, Table } from '../../components/Table'
import './styles.css'

import { maskPeriodo } from '../../services/utils'
import { Link } from 'react-router-dom'
import { FaInfo } from 'react-icons/fa'

function Financeiro() {
  const [clientes, setClientes] = useState<Array<iSelect>>([])
  const [idcliente, setIdcliente] = useState<string>('')
  const [titulos, setTitulos] = useState<Array<iRow>>([])
  const [loadingTitulos, setLoadingTitulos] = useState(false)

  useEffect(() => {
    buscaClientes()
  }, [])

  function buscaClientes() {
    let _clientes: Array<iSelect> = []
    api
      .get('/clientes')
      .then(res => {
        if (res.data.mensagem === 'Erro na busca: operador') {
          alert(res.data.mensagem)
          return
        }
        const data = res.data as Array<iCliente>

        _clientes = data.map((value, index) => {
          return {
            label: `${value.txnome_cliente} ${value.txsobrenome_cliente}`,
            value: value.idcliente.toString()
          }
        })

        setClientes(_clientes)

        //   //carrega combobox dos clientes do operador
        //   res.data.map(function (item: any) {
        //     rClienteOperador.push({ value: item.idcliente, label: item.nmcliente });
        //   });
        //   console.log('clienteoperador no loop: ' + rClienteOperador[0].value);
        //   setclientesOperador(rClienteOperador);

        //   params.filtro = [{
        //     campo: 'idcliente',
        //     valor: rClienteOperador[0]?.value,
        //     operacao: '='
        //   }];

        //   console.log(params);

        //   api.post("/tituloReceber/listv1/", params).then((res) => {
        //     console.log('ret busca2');
        //     console.log(res.data);
        //     var r: number;
        //     r = res.data.status;

        //     console.log(r);
        //     if (r === 0) {
        //       console.log('erro na busca')
        //       settitulosReceber([]);

        //     } else {
        //       console.log('buscaok');
        //       settitulosReceber(res.data);

        //     }
      })
      .catch(e => {
        alert('Ocorreu um erro ao buscar os clientes, tente novamente')
      })

    // });

    // setLoading(false);
  }

  function buscaTitulos(idcliente: string) {
    setLoadingTitulos(true)
    api
      .get('/titulos/receber', { params: { idcliente } })
      .then(res => {
        const data = res.data as Array<iTituloReceber>
        let _titulos: Array<iRow> = []

        _titulos = data.map((value, index) => {
          return {
            rowId: value.idtitulo_receber.toString(),
            values: [
              maskPeriodo(value.nrperiodo_referencia),
              value.vltitulo?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }),
              value.vlpago?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }),
              value.tcsituacao_titulo,
              new Date(value.dtvencimento).toLocaleDateString(),
              value.dtpagamento,
              <div key={value.idtitulo_receber.toString()}>
                <Link
                  to={{
                    pathname: 'financeiro/detalhe',
                    state: { idtitulo: value.idtitulo_receber }
                  }}
                >
                  <FaInfo />
                </Link>
                {/* <Button to="financeiro/detalhe" ><FontAwesomeIcon icon={faInfo} /></Button> */}
              </div>
            ]
          }
        })

        setTitulos(_titulos)
      })
      .catch(e => {
        console.log(e)
        alert('Ocorreu um erro ao buscar os titulos')
      })
      .finally(() => {
        setLoadingTitulos(false)
      })
  }

  return (
    <div id="page-operador">
      <PageHeader></PageHeader>
      <Sidebar></Sidebar>

      <div className="container">
        <div className="content">
          <div className="bloco">
            <Select
              name="cliente"
              label=""
              placeholder="Selecione um cliente"
              value={idcliente}
              options={clientes}
              onChange={e => {
                setIdcliente(e.target.value)
                buscaTitulos(e.target.value)
              }}
            />
          </div>
          <div className="bloco">
            <Table
              labels={[
                'Período',
                'Valor',
                'Valor Pago',
                'Situação',
                'Vencimento',
                'Dt Pagamento',
                'Ações'
              ]}
              name="table-faturas"
              loading={loadingTitulos}
              qtloading={5}
              data={titulos}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Financeiro
