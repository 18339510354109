import React from 'react'
import './assets/styles/global.css'
import { AuthProvider } from './contexts/auth'
import Loading from './pages/Loading'
import Routes from './routes'

class App extends React.Component {
  render() {
    return (
      <AuthProvider>
        <Routes />
        <Loading />
      </AuthProvider>
    )
  }
}

export default App
