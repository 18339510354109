import { iLoginResponse, iOperador, iResponse } from '../interfaces/interfaces'
import api from './api'


export const TOKEN_KEY = '@RNAuth:token'
// export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
// export const isAuthenticated = () => true;
export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const xlogin = (token: string, operador: string) => {
  localStorage.setItem('operador', operador)
  localStorage.setItem(TOKEN_KEY, token)
}

// export function signIn(login:string, senha:string, captcha?: string): Promise<Response> {
export function signIn(login: string, senha: string): Promise<iLoginResponse> {
  return new Promise(resolve => {
    // console.log(login)
    // console.log(senha)

    // api.post("/login", { login, senha, "g-recaptcha-response": captcha}).then((res) => {
    api
      .post('/login', { login, senha })
      .then(res => {
        resolve(res.data)
      })
      .catch(res => {
        resolve(res.data)
      })
  })
}

export const logout = () => {
  localStorage.clear()
}
