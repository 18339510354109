import React, { SelectHTMLAttributes, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import './styles.css'

export interface iSelect {
  value: string
  label: string
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  label: string
  options: Array<iSelect>
  loading?: boolean
  blink?: boolean
  invalid?: boolean
  ref?: any
}

export const Select: React.FC<SelectProps> = ({
  label,
  name,
  placeholder,
  options,
  ref,
  loading,
  blink,
  invalid,
  ...rest
}) => {
  let _ref: any
  useEffect(() => {
    if (invalid) {
      if (_ref) _ref.setCustomValidity('INVÁLIDO')
    } else {
      if (_ref) _ref.setCustomValidity('')
    }
  }, [invalid])
  return (
    <div className={blink ? 'select-block Blink' : 'select-block'}>
      <label htmlFor={name}>{label}</label>
      {loading ? (
        <div className="select-skeleton">
          <Skeleton height={50} width={100} />
        </div>
      ) : (
        <select
          name={name}
          id={name}
          ref={e => {
            _ref = e
            ref = e
          }}
          {...rest}
        >
          <option selected={true} value="" disabled hidden>
            {placeholder}
          </option>

          {options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </select>
      )}
    </div>
  )
}
