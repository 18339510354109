import React, { InputHTMLAttributes, LegacyRef, useEffect } from 'react'

import './styles.css'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  customClass?: string
  name: string
  label: string
  invalid?: boolean
  ref?: any
}

const Input: React.FC<InputProps> = React.forwardRef(function Input(
  { invalid, ...rest },
  ref
) {
  let _ref: any
  useEffect(() => {
    if (invalid) {
      if (_ref) _ref.setCustomValidity('INVÁLIDO')
    } else {
      if (_ref) _ref.setCustomValidity('')
    }
  }, [invalid])
  return (
    <div className={'input-block' + ' ' + rest.customClass}>
      <label htmlFor={rest.name}>{rest.label}</label>
      <input
        autoComplete={'disabled'}
        ref={e => {
          _ref = e
          ref = e as any
        }}
        type="text"
        id={rest.name}
        {...rest}
      />
    </div>
  )
})

export default Input
