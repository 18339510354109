import React, { TableHTMLAttributes } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { FaEraser } from 'react-icons/fa'
import Skeleton from 'react-loading-skeleton'

import './styles.css'

export interface iRow {
  rowId: string
  values: Array<any>
}

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  customClass?: string
  name: string
  labels: Array<any>
  data: Array<iRow>
  botao?: any
  loading?: boolean
  qtloading?: number
  hide?: boolean
}

export const Table: React.FC<TableProps> = ({
  customClass,
  name,
  data,
  labels,
  botao,
  loading,
  qtloading,
  hide,
  ...rest
}) => {
  const [loadingRows, setLoadingRows] = useState<Array<number>>([])

  useEffect(() => {
    if (qtloading) {
      const loadingRows = []
      for (let i = 0; i < qtloading; i++) {
        loadingRows.push(i)
      }
      setLoadingRows(loadingRows)
    }
  }, [qtloading])

  // if(hide){
  //     return(<div></div>)
  // }else{

  return (
    <div
      className={
        'table-block' +
        ' ' +
        (customClass ? customClass : '') +
        ' ' +
        (hide ? 'divHide' : '')
      }
    >
      <table {...rest}>
        <thead>
          <tr>
            {labels.map((label, index) => {
              return <td key={index}>{label}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {loading
            ? loadingRows.map((value: number, index: number) => {
              return (
                <tr key={index}>
                  {labels.map((label, index) => {
                    return (
                      <td key={index}>
                        <Skeleton height={30} width={100} />
                      </td>
                    )
                  })}
                </tr>
              )
            })
            : data.map((row, index) => {
              return (
                <tr key={row.rowId}>
                  {row.values.map((value, index) => {
                    // if(  typeof value  === "object" && value != null && value   ){
                    //     return (<td className={value.className}><FontAwesomeIcon icon={faCircle}></FontAwesomeIcon> </td>);
                    // }else{
                    return <td key={index}>{value}</td>
                    // }
                  })}
                  {botao && botao.funcao ? (
                    <a
                      onClick={() => {
                        botao.funcao(row.rowId)
                      }}
                    >
                      <label className="icon" htmlFor={name}>
                        <FaEraser />
                      </label>
                    </a>
                  ) : (
                    ''
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
  // }
}
