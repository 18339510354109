import React, { useState, useEffect } from 'react'
import Input from '../../components/Input'

import './styles.css'

function ForgotPassword() {
  const [email, setEmail] = useState('')

  return (
    <div id="page-forgot-password">
      <div className="content">
        <div className="password-reset-box">
          <div className="password-reset-box-header">
            {/* <img src={logoImg} alt="Unimed Itapetininga" /> */}
            <p>Redefinir senha</p>
            <small>
              Digite seu e-mail abaixo para que possamos enviar um link de
              redefinição de senha
            </small>
          </div>
          <div className="password-reset-box-body">
            <Input
              type="email"
              placeholder="E-mail"
              label=""
              name="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
          </div>
          <div className="password-reset-box-footer">
            {/* <div className="controls">
                        </div> */}
            <button type="button">Enviar E-mail</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
