import React, { InputHTMLAttributes } from 'react'

import './styles.css'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
}

const Checkbox: React.FC<CheckboxProps> = ({ label, name, ...rest }) => {
  return (
    <div className="checkbox-block input-block">
      <label className="checkbox" htmlFor={name}>
        {' '}
        {label}
        <input type="checkbox" id={name} name="remeber-me" {...rest} />
        <span className="checkmark"></span>
      </label>
    </div>
  )
}

export default Checkbox
